.input {
  position: relative;
  &_code {
    overflow: hidden;
    position: relative;
    background-color: #A7A7AB;
    border-radius: 2px;
    box-shadow: inset 0px 1.59906px 3.99765px rgba(0, 0, 0, 0.05);
    height: 40px;
  }
  &__element {
    border-style: solid;
    border-width: 1px;
    border-color: var(--color-input-border, #A7A7AB);
    line-height: 22px;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 2px;
    transition: border-color $trs1, box-shadow $trs1, color $trs1, background-color 5000s;
    width: 100%;
    font-size: 0.875rem;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: inherit;
      box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::placeholder {
      color: var(--color-text-muted, #767676);
      transition: opacity $trs1, color $trs1;
    }
    &_textarea {
      min-height: 100px;
      resize: none;
    }
    &.focused {
      box-shadow: 0px 0px 0px 2px var(--color-input-focus-shadow, #E7EEF6);
      border-color: var(--color-input-focus, #2363D1);
      &::placeholder {
        opacity: 0;
      }
      & ~ .input__label {
        color: var(--color-input-focus, #2363D1);
      }
    }
    &.filled {
      & ~ .input__label {
        opacity: 1;
        visibility: visible;
      }
    }
    &.error {
      box-shadow: 0px 0px 0px 2px var(--color-input-error-shadow, #F6E7E7);
      border-color: var(--color-input-error, #C43228);
      color: var(--color-input-error, #C43228);
      & ~ .input__label {
        color: var(--color-input-error, #C43228);
      }
      & ~ .input__message {
        display: block;
      }
      &::placeholder {
        color: var(--color-input-error, #C43228);;
      }
    }
  }
  &__label {
    display: block;
    font-size: 0.625rem;
    line-height: 1.5;
    text-transform: uppercase;
    position: absolute;
    bottom: 100%;
    opacity: 0;
    visibility: hidden;
    transition: color $trs1, opacity $trs1, visibility $trs1;
  }
  &__message {
    display: none;
    color: var(--color-input-error, #C43228);
    font-size: 0.75rem;
    margin-top: 4px;
    line-height: 1.125rem;
  }
}

.form-section {
  padding: 40px 0;
  background-color: var(--color-sections-bg, #F5F5F5);
  &_style-2 {
    @include media-breakpoint-up(lg) {
      
      .form-section {
        &__content {
          border-radius: 0;
          padding: 40px;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      padding: 0;
    }
    @include media-breakpoint-down(xs) {
      margin-right: -16px !important;
      margin-left: -16px !important;
      padding-right: 8px;
      padding-left: 8px;
      .form-section {
        &__content {
          margin: 0;
        }
      }
    }
  }
  &:last-child {
    margin: 0;
  }
  &__content {
    background-color: #fff;
    padding: 24px 100px 46px 100px;
    border: 3px solid var(--color-accent, #2363D1);
    box-shadow: 0px 2px 25px rgba(118, 118, 118, 0.15);
    border-radius: 8px;
  }
  &__title {
    text-align: center;
  }
  &__label {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 12px;
    span {
      margin-right: 10px;
      &:last-child {
        color: #000;
      }
    }
  }
}

.form-post-text {
  display: block;
  margin-top: 20px;
  font-size: 0.75rem;
  color: var(--color-text-muted, #767676);
  line-height: 1.5;
}

.form {
  &__input, &__upload {
    margin-bottom: 20px;
  }
  p {
    margin: 0 0 28px 0;
  }
  &__group {
    margin-bottom: 20px;
    &-content {
      margin-bottom: -20px;
    }
  }
  &__bottom {
    margin-top: 8px;
  }
  &__upload-button {
    input {
      display: none;
    }
  }
}

.selectric {
  position: relative;
  width: 100%;
  background-color: #fff;
  line-height: 20px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    cursor: pointer;
    padding: 9px 40px 9px 15px;
    transition: color $trs1;
    font-size: 0.875rem;
  }
  .icon {
    width: 12px;
    height: 7px;
    position: absolute;
    top: 17px;
    right: 16px;
    display: block;
    stroke: none;
    fill: var(--color-accent, #2363D1);
    pointer-events: none;
  }
  &-wrapper {
    position: relative;
    height: 100%;
  }
  &-responsive {
    width: 100%;
  }
  &-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
    select {
      position: absolute;
      left: -100%;
    }
  }
  &-input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  &-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
  }
  &-scroll {
    height: 100%;
    overflow: auto;
    outline: none;
  }
  &-items {
    transition: visibility $trs1, opacity $trs1;
    position: absolute;
    display: block;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    ul {
      list-style: none !important;
      padding: 0 !important;
      margin: 0 !important;
      outline: none;
    }
    li {
      transition: color $trs1, background-color $trs1;
      margin: 0;
      cursor: pointer;
      font-size: inherit;
      font-size: 0.875rem;
      line-height: 1.25rem;
      &:before {
        display: none;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      &.selected {
        pointer-events: none;
      }
    }
  }
  &-open {
    z-index: 9999;
    .selectric-items {
      opacity: 1;
      visibility: visible;
    }
  }
  &-disabled {
    color: var(--color-text-muted, #767676);
    cursor: default;
    user-select: none;
    pointer-events: none;
  }
}
.selectric-select {
  &_style-1 {
    .selectric {
      border-color: var(--color-border-1, #D9D9DE);
      .label {
        &[data-touch], &[data-hover] {
          color: var(--color-accent, #2363D1);
        }
      }
      .icon {
        transition: transform $trs1;
      }
      &-items {
        box-shadow: $shadow;
        background: var(--color-sections-bg, #F5F5F5);
        border-radius: 2px;
        border: 1px solid var(--color-border-1, #D9D9DE);
        top: 0;
        left: 0;
        li {
          padding: 9px 15px;
          &.selected {
            background-color: var(--color-accent, #2363D1);
            color: #fff;
          }
          &[data-touch], &[data-hover] {
            color: var(--color-accent, #2363D1);
          }
        }
      }
    }
    &.selectric-open {
      .selectric {
        border-color: var(--color-border-1, #D9D9DE);
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  &_style-2 {
    .selectric {
      border-color: var(--color-border-3, #A7A7AB);
      .label {
        padding-left: 10px;
        &[data-touch], &[data-hover] {
          color: var(--color-accent, #2363D1);
        }
      }
      .icon {
        transition: transform $trs1;
      }
      &-items {
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
        background: #fff;
        border-radius: 0 0 2px 2px;
        border-color: var(--color-border-3, #A7A7AB);
        border-style: solid;
        border-width: 0 1px 1px 1px;
        top: 100%;
        left: 0;
        li {
          padding: 10px 20px;
          &.selected {
            background-color: var(--color-accent, #2363D1);
            color: #fff;
          }
          &[data-touch], &[data-hover] {
            color: var(--color-accent, #2363D1);
          }
        }
      }
    }
    &.selectric-open {
      .selectric {
        border-radius: 2px 2px 0 0;
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  &_style-2.selectric-select_top {
    .selectric-items {
      top: auto;
      bottom: 100%;
      border-width: 1px 1px 0 1px;
      border-radius: 2px 2px 0 0;
    }
    &.selectric-open {
      .selectric {
        border-radius: 0 0 2px 2px;
      }
    }
  }
}

.checkbox {
  position: relative;
  &__input {
    display: none;
    &:checked ~ .checkbox__label {
      &:before {
        background-color: var(--color-checkbox-hover-color, #2363D1);
        border-color: var(--color-checkbox-hover-color, #2363D1);
      }
      &:after {
        opacity: 1;
      }
    }
  }
  &__label {
    display: block;
    padding: 0 24px 0 30px;
    line-height: 20px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid var(--color-checkbox-border, #000);
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 0;
      transition: border-color $trs1, background-color $trs1;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 6px;
      border-width: 0 0 2px 2px;
      transform: rotate(-45deg);
      border-style: solid;
      border-color: #fff;
      opacity: 0;
      transition: opacity $trs1;
    }
  }
  &__count {
    font-size: 0.75rem;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-text-light, #BDBDBD);
    min-width: 24px;
    text-align: center;
  }
}

.radio {
  position: relative;
  &__input {
    display: none;
    &:checked ~ .radio__label {
      &:after {
        opacity: 1;
      }
    }
  }
  &__label {
    display: block;
    padding: 0 24px 0 30px;
    line-height: 20px;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid var(--color-checkbox-border, #000);
      border-radius: 20px;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: var(--color-checkbox-hover-color, #2363D1);
      opacity: 0;
      transition: opacity $trs1;
    }
  }
  &__count {
    font-size: 0.75rem;
    line-height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-text-light, #BDBDBD);
    min-width: 24px;
    text-align: center;
  }
}

.calc-count-block {
  width: 100px;
  display: flex;
  align-items: center;
  &_style-2 {
    .calc-count-block {
      &__input {
        .input__element {
          box-shadow: none;
          border: none;
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  &__input {
    flex: 1 1 100%;
    margin: 0;
    padding: 0 !important;
    .input__element {
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;
      background-color: transparent;
    }
  }
  &__button {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    min-width: 20px;
    .icon {
      width: 10px;
      height: 10px;
    }
  }
}
  


@include media-breakpoint-down(lg) {
  .form-section {
    &__content {
      padding: 30px;
    }
  }
  .checkbox__count,
  .radio__count {
    position: relative;
    margin-left: 8px;
  }
}

@include media-breakpoint-down(md) {
  .form-section {
    &__content {
      border-width: 2px;
      padding: 30px 16px;
    }
  }
}
@include media-breakpoint-up(md) {
  .form {
    &__upload-button {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}
@include media-breakpoint-down(sm) {
  .form {
    &__input, &__upload {
      margin-bottom: 16px;
    }
    p {
      margin: 0 0 20px 0;
    }
    &__link {
      display: inline-block;
      margin-top: 16px;
    }
    &__group {
      margin-bottom: 16px;
      &-content {
        margin-bottom: -16px;
      }
    }
    &__submit {
      width: 100%;
    }
    &__upload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__upload-button {
      width: 100%;
      max-width: 240px;
    }
  }
  .form-post-text {
    margin-top: 16px;
  }
  .form-section {
    &__title {
      margin-bottom: 24px;
    }
    &__content {
      border-width: 2px;
      padding: 32px 6px;
      margin: 0 -8px;
    }
  }
  .input {
    &.filled {
      padding-top: 15px;
      .input__label {
        bottom: auto;
        top: 0;
      }
    }
  }
}


//range
.irs--round .irs-from, .irs--round .irs-single, .irs--round .irs-to,
.irs--round .irs-max, .irs--round .irs-min {
  display: none;
}
.irs--round {
  height: 20px;
}
.irs--round {
  height: 20px;
  .irs {
    height: inherit;
  }
  .irs-handle {
    top: 0;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 20px;
    background-color: var(--color-accent, #2363D1) !important;
    box-shadow: none;
  }
  .irs-line,
  .irs-bar {
    height: 8px;
    border-radius: 8px;
    top: 6px;
  }
  .irs-bar {
    background-color: var(--color-accent, #2363D1);
  }
  .irs-line {
    background-color: var(--color-border-1, #D9D9DE);
  }
}