h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #000;
  margin: 1.875rem 0 1rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  & + h2, .h2,
  & + h3, .h3,
  & + h4, .h4,
  & + h5, .h5,
  & + h6, .h6 {
    margin-top: 1.5rem;
  }
  &.font2 {
    font-weight: 400;
  }
}

h1, .h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
h2, .h2 {
  font-size: 1.375rem;
  line-height: 1.75rem;
}
h3, h4, .h3, .h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}
h5, h6, .h5, .h6 {
  font-size: 1rem;
  line-height: 1.25rem;
}

mark {
  background-color: transparent;
  color: var(--color-accent, #2363D1);
}

p {
  margin: 0 0 1rem 0;
  &:last-child {
    margin: 0;
  }
}

.video {
  display: block;
  position: relative;
  margin: 1.25rem 0;
  &::before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
  iframe {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

picture {
  display: block;
  margin: 1.5rem 0;
  max-width: 100%;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  img {
    display: block;
    width: auto;
    width: 100%;
    max-width: 100%;
  }
}

figure {
  margin: 1.25rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
figcaption {
  display: block;
  margin: 10px 0 0 0;
  color: var(--color-text-muted, #767676);
  font-size: 0.75rem;
}

img:not([class]) {
  width: auto;
  max-width: 100%;
}

blockquote {
  padding: 20px 50px;
  background-color: #E5E5E5;
  margin: 0;
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
}

ul:not([class]) {
  padding: 0;
  margin: 0;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
  li:not([class]) {
    position: relative;
    padding-left: 23px;
    margin: 0 0 1rem 0;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0.55rem;
      width: 12px;
      height: 2px;
      background-color: var(--color-ul-item, #2363D1);
    }
    &:last-child {
      margin: 0;
    }
  }
}

ol:not([class]) {
  counter-reset: section;
  padding: 0;
  margin: 0;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
  li:not([class]) {
    padding-left: 49px;
    min-height: 36px;
    position: relative;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &:before {
      counter-increment: section;
      content: counters(section,".") " ";
      border-width: 2px;
      border-style: solid;
      border-color: var(--color-ol-item-border, #E5E5EA);
      color: var(--color-ol-item, #2363D1);
      line-height: 32px;
      text-align: center;
      width: 36px;
      display: block;
      border-radius: 36px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.table {
  overflow: auto;
}
table {
  border-collapse: collapse;
}
table:not([class]) {
  border-collapse: collapse;
  min-width: 100%;
  font-size: 0.875rem;
  th {
    font-weight: 700;
    padding: 10px 16px 10px 0;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      text-align: center;
    }
  }
  td {
    padding: 12px 16px 12px 0;
    transition: background-color $trs1;
    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      text-align: center;
    }
  }
  th,
  td {
    border-bottom: 1px solid var(--color-table-border, #E5E5EA);
    text-align: left;
    width: 25%;
  }
  tr {
    &[data-touch], &[data-hover] {
      td {
        background-color: var(--color-table-row-hover, #F5F5F5);
      }
    }
  }
}

a:not([class]), .link {
  color: var(--color-accent-darker, #1C52AC);
  text-decoration: none;
  transition: color $trs1, border-color $trs1;
  position: relative;
  border-bottom: 1px solid transparent;

  &[data-touch], &[data-hover] {
    color: var(--color-accent-darker, #1C52AC);
    border-color: currentColor;
  }
}

.link {
  &_without-border {
    border: none;
  }
}

a:not([class]) {
  &:visited {
    color: var(--color-link-visited, #B46CB8);
  }
}

@include media-breakpoint-down(sm) {
  h1, .h1 {
    font-size: 1.625rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }
  h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin: 1.5rem 0 1rem 0;
    &:not([class]) {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  img:not([class]) {
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);
    max-width: calc(100% + 32px);
  }
  blockquote {
    padding: 24px 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }
  .table {
    margin-left: -16px;
    margin-right: -16px;
  }
  ol:not([class]) {
    li:not([class]) {
      min-height: 24px;
      padding-left: 40px;
      &:before {
        width: 24px;
        height: 24px;
        line-height: 20px;
      }
    }
  }

  ul:not([class]), ol:not([class]), p, blockquote {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}