.image-zoom {
  overflow: hidden;
  .image {
    transition: transform $trs1;
    will-change: transform;
  }
  &[data-touch], &[data-hover] {
    .image {
      transform: scale(1.05);
    }
  }
}

.icon {
  width: 15px;
  height: 15px;
  fill: #000;
  stroke: #000;
}

.fixed-btn {
  z-index: 100;
  position: fixed;
  width: 38px;
  height: 38px;
  border-radius: 8px 8px 8px 0;
  display: flex;
  color: var(--color-fixed-btn, #fff);
  background-color: var(--color-bg-fixed-btn, #000);
  transition: opacity $trs1, visibility $trs1;
  .icon {
    width: 20px;
    height: 20px;
    margin: auto;
    transition: opacity $trs1;
  }
  &[data-touch], &[data-hover] {
    .icon {
      opacity: 0.8 !important;
    }
  }
}

.js-scroll-top {
  opacity: 0;
  visibility: hidden;
  bottom: 65px;
  right: 20px;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  @include media-breakpoint-down(sm) {
    bottom: 60px;
    right: 15px;
  }
}

.safety-message-fixed {
  position: fixed;
  z-index: 97;
  display: block;
  bottom: 20px;
  right: 20px;
  &__popup {
    position: absolute;
    font-size: 14px;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    padding: 20px 30px;
    width: 260px;
    border: 1px solid var(--color-border-1);
    opacity: 0;
    visibility: hidden;
    transition: opacity $trs1, visibility $trs1;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  &__btn {
    position: relative;
    &:before, 
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 22px;
      height: 2px;
      background-color: var(--color-fixed-btn, #fff);
      top: 19px;
      left: 8px;
      transition: opacity $trs1;
      opacity: 0;
    }
    &::before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &.active {
      .icon {
        opacity: 0 !important;
      }
      &:before, 
      &:after {
        opacity: 1;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    bottom: 15px;
    right: 15px;
  }
}

[data-fancybox] {
  cursor: zoom-in;
}

.content-placeholder {
  background: #b1b1b1;
  color: #fff;
  position: relative;
  text-align: center;
  font-size: 20px;
  border-radius: var(--content-blocks-radius, 0px);
  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
  }
}

.content-image {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: var(--content-blocks-radius, 0px);
  transform: translateZ(0);
  .image {
    img {
      transition: opacity $trs1, visibility $trs1;
      &:not(:first-child) {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }
  }
  &[data-touch], &[data-hover] {
    .image {
      img {
        &:not(:first-child) {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.content-standard-box {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
  }
}

.content-background-box {
  overflow: hidden;
  border-radius: var(--content-blocks-radius, 0px);
  &__container {
    position: relative;
    min-height: 180px;
    height: 100%;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &__background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &__text-content {
    position: relative;
    z-index: 3;
    padding: 25px 20px;
  }
}

.product-card-v2 {
  --pd: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  .slick-slider & {
    display: flex !important;
  }
  &__link {
    position: absolute;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__image {
    position: relative;
    z-index: 1;
    height: 170px;
    flex: 0 0 auto;
    .image {
      height: 100%;
      img {
        padding: var(--pd);
        transition: opacity $trs1, visibility $trs1, transform $trs1;
        will-change: transform;
        &:not(:first-child) {
          opacity: 0;
          visibility: hidden;
        }
      }
      &_no-image {
        background-color: #F5F5F5;
        img {
          padding: 25px !important;
        }
      }
    }
  }
  &__labels {
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
  &__label {
    padding: 0 8px;
    width: auto;
    font-weight: 500;
  }
  &__rating {
    position: absolute;
    width: 85px;
    height: auto;
    top: 5px;
    right: 5px;
    z-index: 2;
  }
  &__rating-stars {
    display: block;
    width: 100%;
    height: auto;
  }
  &__title {
    hyphens: auto;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: 15px;
    line-height: 1.5;
  }
  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px var(--pd) var(--pd) var(--pd);
  }
  &__content-bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: var(--pd);
  }
  &__button {
    position: relative;
    z-index: 3;
  }
  &__cart-button {
    width: 100%;
    padding: 0;
    position: relative;
    z-index: 3;
    .icon {
      width: 26px;
      height: 26px;
    }
    & > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      row-gap: 4px;
      span {
        line-height: 1;
        &:not(:last-child) {
          font-size: 10px;
        }
        &:not(:first-child) {
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
  }
  &__pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1;
    gap: 6px;
  }
  &__pricing-txt {
    font-size: 12px;
    line-height: 1.25;
  }
  &__price {
    font-size: 20px;
    font-weight: 700;
    &.text-color-accent {
      span {
        color: var(--color-text, #333333);
      }
    }
    span {
      font-weight: normal;
      font-size: 16px;
    }
  }
  &__old-price {
    color: var(--color-text-muted, #767676);
    font-size: 16px;
    position: relative;
    padding: 0 3px;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--color-old-price, #C43228);
      top: calc(45% - 1px);
      left: 0;
    }
  }
  &[data-touch], &[data-hover] {
    .product-card-v2__image {
      .image {
        img {
          transform: scale(1.05);
          &:not(:last-child) {
            opacity: 0;
            visibility: hidden;
          }
          &:not(:first-child) {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    border: 1px solid var(--color-card-border, transparent);
    border-radius: var(--product-preview-radius, 2px);
    overflow: hidden;
    transition: border-color $trs1;
    &[data-touch], &[data-hover] {
      border-color: var(--color-card-hover-border, transparent);
    }
  }
  @include media-breakpoint-down(sm) {
    --pd: 0;
    &__link {
      width: calc(100% + var(--grid-gutter-width));
      height: calc(100% + var(--grid-gutter-width));
      top: calc(var(--grid-gutter-width) / -2);
      left: calc(var(--grid-gutter-width) / -2);
    }
    &__content {
      padding-top: calc(var(--grid-gutter-width) / 2);
      padding-bottom: calc(var(--grid-gutter-width) / 2);
    }
    &__labels {
      bottom: calc(var(--grid-gutter-width) / 2);
    }
    &__rating {
      top: calc(var(--grid-gutter-width) / 2);
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 5;
      width: calc(100% + var(--grid-gutter-width) + 1px);
      height: 100%;
      left: calc((var(--grid-gutter-width) + 1px) / -2);
      border: 1px solid var(--color-card-border, transparent);
      pointer-events: none;
    }
    &__title {
      font-size: 12px;
      margin-bottom: calc(var(--grid-gutter-width) / 2);
    }
    &__pricing-txt {
      font-size: 10px;
    }
    &__image {
      .image {
        width: calc(100% + var(--grid-gutter-width));
        margin-left: calc(var(--grid-gutter-width) / -2);
        img {
          padding: calc(var(--grid-gutter-width) / 2);
        }
      }
    }
    &__price {
      font-size: 16px;
      span {
        font-size: 12px;
      }
    }
    &__old-price {
      font-size: 12px;
    }
    &__buttons {
      gap: calc(var(--grid-gutter-width) / 2);
    }
    &__labels {
      gap: 2px;
    }
    &__label {
      font-size: 10px;
      line-height: 15px;
      padding: 0 5px;
    }
    &__button {
      height: 40px;
      line-height: 38px;
      padding: 0 10px;
      min-width: initial;
      font-size: 14px;
    }
    &__cart-button {
      padding: 0 10px;
      min-width: initial;
      height: 40px;
      line-height: 38px;
      .icon {
        width: 20px;
        height: 20px;
      }
      & > span {
        span {
          &:not(:last-child) {
            font-size: 8px;
          }
          &:not(:first-child) {
            font-size: 12px;
          }
        }
      }
    }

    &_type-1 {
      .gap-grid > & {
        &:not(:nth-child(1)):not(:nth-child(2)) {
          margin-top: calc(var(--grid-gutter-width) * -1 - 1px);
        }
      }
      .product-card-v2 {
        &__rating {
          right: 0;
        }
        &__image {
          height: 120px;
        }
      }
    }

    &_type-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--grid-gutter-width);
      min-height: 160px;
      .gap-grid > & {
        &:not(:nth-child(1)) {
          margin-top: calc(var(--grid-gutter-width) * -1 - 1px);
        }
      }
      .slider-constructor .slick-slide > div:not(:first-child) & {
        margin-top: calc(var(--grid-gutter-width) / -2 - 0.5px);
      }
      .slider-constructor .slick-slide > div:not(:last-child) & {
        margin-bottom: calc(var(--grid-gutter-width) / -2 - 0.5px);
      }
      .slider-constructor .slick-slide & {
        display: grid !important;
      }
      .product-card-v2 {
        &__image {
          height: 100%;
        }
        &__rating {
          right: initial;
          left: 0;
        }
      }
    }
  }
}

.background-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &__element {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}