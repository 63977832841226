.col-20pc {
  flex: 0 0 20%;
  max-width: 20%;
}
.col-80pc {
  flex: 0 0 80%;
  max-width: 80%;
}
@include media-breakpoint-up(sm) {
  .col-sm-20pc {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-sm-80pc {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@include media-breakpoint-up(md) {
  .col-md-20pc {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-md-80pc {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@include media-breakpoint-up(lg) {
  .col-lg-20pc {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-lg-80pc {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@include media-breakpoint-up(xl) {
  .col-xl-20pc {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-80pc {
    flex: 0 0 80%;
    max-width: 80%;
  }
}