.footer {
  flex-shrink: 0;
  padding: 32px 0;
  background-color: var(--color-footer-background, #333333);
  color: var(--color-footer-text, #fff);
  &__logo {
    margin-bottom: 38px;
    svg {
      fill: var(--color-footer-logo, #fff);
    }
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__contact-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.875rem;
    a {
      color: inherit;
      text-decoration: none;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }
  &__phone {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__link {
    text-decoration: none;
    color: inherit;
    opacity: 0.5;
    transition: opacity $trs1;
    display: inline-block;
    font-size: 0.75rem;
    &[data-touch], &[data-hover] {
      opacity: 1;
    }
  }
  &__section {
    margin-bottom: 30px;
    &-title {
      font-size: 0.875rem;
      font-weight: 700;
      display: block;
    }
  }
  &__section-contact {
    display: flex;
    flex-direction: column;
  }
  &__nav-list {
    padding: 0;
    list-style: none;
    margin: 10px 0 0 0;
  }
  &__nav-item {
    font-size: 0.75rem;
    display: block;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
  &__subscription {
    margin-top: 4px;
    width: 280px;
    margin-bottom: 13px;
    &-head {
      font-size: 0.75rem;
      color: var(--color-text-muted, #767676);
      display: block;
      margin-bottom: 12px;
    }
  }
}

.subscription {
  display: flex;
  background-color: #fff;
  border-radius: 2px;
  &__input {
    flex: 1 1 100%;
    padding: 0 !important;
    &-element {
      box-shadow: none !important;
      border-radius: 2px 0 0 2px;
      border-width: 1px 0 1px 1px;
    }
  }
  &__button {
    flex: 0 0 auto;
    min-width: 80px;
    border-radius: 0 2px 2px 0;
  }
}

.copy {
  font-size: 0.75rem;
  color: var(--color-text-muted, #767676);
  opacity: 0.5;
}

.footer-socials {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  &__item {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &__link {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    transition: opacity $trs1;
    position: relative;
    overflow: hidden;
    display: flex;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-footer-socials-bg, #fff);
      opacity: 0.5;
      transition: opacity $trs1;
    }
    .icon {
      position: relative;
      z-index: 2;
      fill: var(--color-footer-socials-icon, #4F4F4F);
      width: 14px;
      height: 14px;
      margin: auto;
    }
    &[data-touch], &[data-hover] {
      &:before {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .footer {
    &-socials {
      justify-content: flex-start;
      margin-top: 32px;
    }
  }
}
@include media-breakpoint-down(md) {
  .footer {
    &__bottom {
      flex-direction: column;
      align-items: flex-start;
    }
    .copy {
      order: 2;
      margin-bottom: 10px;
    }
    &__link {
      order: 3;
    }
  }
}
@include media-breakpoint-down(sm) {
  .footer {
    padding: 50px 0;
    text-align: center;
    &__subscription {
      width: 100%;
    }
    &__logo {
      margin-bottom: 48px;
      .logo__link {
        width: 210px;
        margin: 0 auto;
      }
    }
    &-socials {
      justify-content: space-between;
      max-width: 240px;
      width: 100%;
      margin: 0 auto;
      margin-top: 32px;
      &__link {
        width: 32px;
        height: 32px;
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
    &__section {
      flex: 1 1 100%;
      margin-bottom: 40px;
    }
    &__section-nav {
      flex: 1 1 50%;
    }
    &__bottom {
      align-items: center;
    }
  }
}

.dev-link {
  display: block;
  width: 200px;
  color: #fff;
  opacity: 0.3;
  transition: opacity $trs1;
  &__image {
    display: block;
    width: 100%;
  }
  &[data-touch], &[data-hover] {
    opacity: 0.6;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
    order: 1;
  }
}
