[data-simplebar] {
  position: relative;
}

.scrollbar {
  position: relative;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 50px;
    z-index: 2;
    left: 0;
    pointer-events: none;
    transition: opacity $trs1;
  }
  &:before {
    top: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
  &:after {
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
  &_start {
    &:before {
      opacity: 0;
    }
  }
  &_end {
    &:after {
      opacity: 0;
    }
  }
  @include media-breakpoint-down(lg) {
    &_mobile {
      & > .scrollbar__content {
        position: relative;
        z-index: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        max-height: inherit;
        height: 100%;
      }
      &::after, &::before {
        width: 100% !important;
        height: 30px;
      }
    }
  }

  &_style-2 {
    .simplebar-track {
      background-color: var(--color-sections-bg, #F5F5F5);
      border: none;
      border-radius: 8px;
    }
    .simplebar-scrollbar:before {
      background-color: var(--color-border-1, #D9D9DE);
      border-radius: 8px;
    }
    .simplebar-track.simplebar-horizontal {
      height: 8px;
    }
    .simplebar-track.simplebar-vertical {
      width: 8px;
    }
  }

}


.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 3;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  background-color: var(--color-border-1, #D9D9DE);
  border-left: 1px solid var(--color-border-2, #BDBDBD);
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  content: '';
  background: var(--color-accent, #2363D1);
  left: 0;
  opacity: 0;
  border-radius: 10px;
  transition: opacity $trs1;
  display: block;
  width: 100%;
  height: 100%;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 1;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 12px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
  width: 100%;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  bottom: 0;
  height: 12px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  height: 100%;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar:before {
  opacity: 1 !important;
}

@include media-breakpoint-up(lg) {
  .simplebar-scrollbar:before {
    opacity: 1 !important;
  }
}
@include media-breakpoint-down(lg) {
  .simplebar-track.simplebar-vertical {
    width: 7px;
    background-color: transparent;
    border: 0;
  }
  .simplebar-scrollbar {
    padding: 2px;
  }
  .simplebar-track.simplebar-horizontal {
    height: 7px;
    background-color: transparent;
    border: 0;
  }
  .scrollbar::after, .scrollbar::before {
    width: calc(100% - 7px);
  }
  .simplebar-scrollbar:before {
    background-color: var(--color-border-1, #D9D9DE);
  }
}