/* Slider */

.slick-slider {
  position: relative;
  display: block;
  user-select: none;
  touch-action: pan-y;
  outline: none;
  * {
    outline: none;
  }
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  display: flex;
  flex-wrap: nowrap;
}
.slick-slide {
  min-height: 1px;
  &.dragging img {
    pointer-events: none;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  z-index: 3;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  margin: 0;
  padding: 0;
  font-size: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  li {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  button {
    width: 11px;
    height: 11px;
    background-color: #F3F6FB;
    border-radius: 11px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-dots-slider, #2363D1);
    display: block;
    transition: border-color $trs1, background-color $trs1;
  }
  .slick-active {
    button {
      background-color: var(--color-dots-slider, #2363D1);
    }
  }
}
.slider {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity $trs1, visibility $trs1;
  &.slick-initialized {
    display: block;
    opacity: 1;
    visibility: visible;
    .slick-slide {
      flex: 0 0 auto;
    }
  }
  &__next,
  &__prev {
    position: absolute;
    width: 40px;
    min-width: 40px;
    padding: 0;
    display: flex;
    border-radius: 40px;
    z-index: 2;
    transition: opacity $trs1, border-color $trs1, background-color $trs1, color $trs1;
    .icon {
      margin: auto;
      width: 14px;
      height: 14px;
    }
    &.slick-disabled {
      pointer-events: none;
      border-color: var(--color-border-2, #BDBDBD);
      background-color: #fff;
      .icon {
        fill: var(--color-border-2, #BDBDBD);
      }
    }
  }
  
  .slick-slide {
    display: flex;
    flex-direction: column;
    &>div:not([class]) {
      display: flex;
      flex: 1 1 auto;
    }
  }
  &_grid {
    .slick-slide {
      padding: 0 calc(var(--grid-gutter-width) / 2);
    }
    .slick-dots {
      display: none !important;
    }
    .slider {
      &__next,
      &__prev {
        top: -60px;
      }
      &__next {
        right: 10px;
      }
      &__prev {
        right: 60px;
      }
    }
  }
  &_dots-bottom {
    padding-bottom: 30px;
  }
  &_2v {
    .slick-slide > div {
      &:not([class]) {
        max-height: calc(50% - 10px);
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &_grid {
      margin-right: calc(var(--container-padding-x) * -1);
      margin-left: calc(var(--container-padding-x) * -1);
      &.slick-dotted {
        padding-bottom: 35px;
      }
      &.slick-initialized {
        &.grid {
          margin-bottom: 0 !important;
          .slick-list {
            margin-bottom: -15px;
          }
          .grid__block {
            margin-bottom: 15px;
          }
        }
      }
      .slick-list {
        padding: 0 8px;
      }
      .slick-dots {
        display: flex !important;
        bottom: 0;
      }
      .slider {
        &__next,
        &__prev {
          display: none !important;
        }
      }
    }
  }
}
.mobile-advertising__slider {
  .slick-arrow {
    display: none !important;
  }
  .slick-track {
    min-width: 100%;
    justify-content: center;
  }
  &.slick-dotted {
    padding-bottom: 35px;
    .slick-dots {
      display: flex !important;
      bottom: 0;
    }
  }
}

.photo-slider {
  &__slide {
    height: 430px;
  }
  .slider {
    &__next,
    &__prev {
      top: 50%;
      transform: translateY(-50%);
    }
    &__next {
      right: var(--container-padding-x);
    }
    &__prev {
      left: var(--container-padding-x);
    }
  }
}

.slider-constructor {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity $trs1, visibility $trs1;
  &.slick-initialized {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .slick-list {
    margin: 0 calc(var(--grid-gutter-width) / -2);
  }
  .slick-dots {
    position: relative;
    margin-top: 25px;
    bottom: initial;
    transform: initial;
    left: initial;
  }
  .slick-slide {
    flex: 0 0 auto;
    display: grid;
    grid-auto-rows: 1fr;
    row-gap: 20px;
    padding: 0 calc(var(--grid-gutter-width) / 2);
    & > div:not([class]) {
      display: flex;
      & > * {
        &:not(.product-card-v2) {
          display: block !important;
        }
      }
    }
  }
  &[data-adaptive-height] {
    .slick-slide {
      height: 100%;
    }
  }
  .slick-arrow {
    position: absolute;
    width: 40px;
    min-width: 40px;
    padding: 0;
    border-radius: 20px;
    z-index: 2;
    .icon {
      width: 14px;
      height: 14px;
    }
    &.slick-disabled {
      pointer-events: none;
      border-color: var(--color-border-2, #BDBDBD);
      background-color: #fff;
      .icon {
        fill: var(--color-border-2, #BDBDBD);
      }
    }
  }
  .slick-prev, .slick-next {
    top: -60px;
  }
  .slick-prev {
    right: 50px;
  }
  .slick-next {
    right: 0;
    .icon {
      transform: rotate(180deg);
    }
  }
  @include media-breakpoint-up(sm) {
    &_no-title {
      &:not([data-no-arrows]) {
        padding-top: 56px;
        .slick-prev, .slick-next {
          top: -4px;
        }
      }
    }
    &:not([data-no-arrows]) {
      .slick-dots {
        display: none !important;
      }
    }
    .slick-prev, .slick-next {
      display: flex !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .slick-list {
      overflow: visible !important;
    }
    .slick-slide {
      row-gap: var(--grid-gutter-width);
    }
    .slick-prev, .slick-next {
      display: none !important;
    }
  }
}