.home-banner {
  --min-height: 400px;
  margin-top: -30px;
  position: relative;
  min-height: var(--min-height);
  .slick-slide {
    min-height: var(--min-height);
  }
  &[data-desktop-ratio][data-mobile-ratio] {
    .slick-list {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .slick-track {
      height: 100%;
    }
    .slick-slide {
      height: 100%;
      min-height: initial !important;
      &>div {
        height: 100%;
      }
    }
    .image {
      img {
        object-position: center right;
      }
    }
  }
  &__slide {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &__slide-container {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 120px;
  }
  &__slide-content {
    width: 350px;
    &_bg {
      padding: 20px;
      border-radius: 4px;
      background-color: var(--color-banner-content-bg, #fff);
    }
    &_right {
      margin: 0 0 0 auto;
    }
  }
  &__arrow {
    position: absolute;
    z-index: 3;
    width: 40px;
    height: 40px;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    svg {
      margin: auto;
      width: 25px;
      height: 25px;
      fill: #fff;
      opacity: 0.75;
      transition: opacity $trs1, fill $trs1;
    }
    &[data-touch], &[data-hover] {
      svg {
        opacity: 1;
        fill: var(--color-accent, #2363D1);
      }
    }
  }
  &__prev {
    left: 36px;
  }
  &__next {
    right: 36px;
  }
  .slick-dots {
    bottom: 20px;
  }
  @include media-breakpoint-up(lg) {
    &:not(:last-child) {
      .slick-dots {
        bottom: 67px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    --min-height: 300px;
    &__slide-container {
      padding-top: 40px;
      padding-bottom: 70px;
    }
    &__slide-content {
      margin: 0 0 0 50px;
      &_right {
        margin: 0 50px 0 auto;
      }
    }
    &__prev {
      left: calc(50% - 358px);
    }
    &__next {
      right: calc(50% - 358px);
    }
  }
  @include media-breakpoint-down(md) {
    &__prev {
      left: calc(50% - 266px);
    }
    &__next {
      right: calc(50% - 266px);
    }
  }
  @include media-breakpoint-down(sm) {
    --min-height: 200px;
    &__slide-container {
      justify-content: center;
    }
    &__slide-content {
      margin: 0;
      width: calc(100% - 70px);
    }
    &__prev {
      left: 4px;
    }
    &__next {
      right: 4px;
    }
  }
}

.home-categories {
  &__block {
    .category-card {
      &__container {
        min-height: initial;
        height: initial;
        &::after {
          position: relative;
          padding-top: 46.42%;
        }
      }
      &__title {
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding: 30px 60px 24px 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        .icon {
          width: 18px;
          height: 18px;
          bottom: 25px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: -47px;
  }
  @include media-breakpoint-only(lg) {
    &__block {
      .category-card {
        &__title {
          padding: 20px 60px 24px 20px;
          font-size: 1rem;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    margin-top: 24px;
    &__block {
      .category-card {
        &__title {
          padding: 30px 60px 24px 30px;
        }
      }
    }
  }
  
  @include media-breakpoint-down(sm) {
    margin-top: 24px;
    margin-bottom: 41px;
  }
}

.about-us {
  background-color: var(--color-sections-bg, #F5F5F5);
  padding: 60px 0;
  position: relative;
  &__background {
    background-size: contain;
    background-position: bottom right;
    width: calc(100vw - 32px);
    max-width: 1180px;
    left: 50%;
    transform: translateX(-50%);
  }
  &-block {
    &__button {
      margin-top: 20px;
      span {
        display: inline;
      }
    }
    &__left-button {
      margin-left: 120px;
    }
    &__title {
      margin: 0 0 40px 0;
    }
    &__container {
      display: flex;
    }
    &__text {
      flex: 1 1 100%;
      padding-left: 30px;
      max-width: 400px;
    }
    &__count {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 0 0 auto;
      &-value {
        font-size: 70px;
        line-height: 88px;
        color: var(--color-accent, #2363D1);
        font-weight: 700;
        display: block;
        border-bottom: 2px solid var(--color-accent, #2363D1);
        margin-bottom: 11px;
      }
      &-label {
        display: block;
        text-align: center;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    &__background {
      max-width: 928px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__container {
      position: relative;
      margin: 0 -4px;
    }
    .container {
      &::after {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
    &__background {
      background-position: center bottom;
      max-width: 688px;
    }
    &-block {
      display: flex;
      flex-direction: column;
      padding: 0;
      &:first-child {
        padding: 0 4px;
        .about-us-block__count {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 0;
        }
        .about-us-block__button-container {
          width: 50%;
          padding-left: 0;
        }
      }
      &:last-child {
        position: absolute;
        right: 0;
        bottom: 0;
        align-items: center;
      }
      &__container {
        flex-direction: column;
      }
      &__count {
        order: 2;
        align-items: center;
        padding: 0 4px;
        width: 100%;
      }
      &__left-button {
        margin-left: 0;
      }
      &__button-container {
        padding: 0 4px;
        order: 3;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &__button {
        text-transform: capitalize;
        order: 3;
      }
      &__text {
        padding: 0;
        order: 1;
        margin-bottom: 24px;
        max-width: 100%;
      }
      &__title {
        margin-bottom: 32px;
      }
      &:last-child {
        .about-us-block__title {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &__background {
      background-position: center bottom;
      max-width: 508px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 40px 0;
    &-block {
      &__button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.services {
  &__more {
    display: flex;
    justify-content: center;
  }
}
.services-item-block {
  &__container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: 130px;
    color: var(--color-block-services, #1C52AC);
    background-color: var(--color-block-services-bg, #F3F6FB);
    border: 1px solid var(--color-block-services-border, #B7CBE5);
    border-radius: 2px;
    overflow: hidden;
    transition: color $trs1, border-color $trs1;
    text-decoration: none;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: var(--color-block-services-hover-bg, #2363D1);
      opacity: 0;
      transition: opacity $trs1;
    }

    &[data-touch], &[data-hover] {
      color: var(--color-block-services-hover, #fff);
      border-color: var(--color-block-services-hover-bg, #2363D1);
      &::after {
        opacity: 1;
      }
      .icon {
        fill: var(--color-block-services-hover, #fff);
      }
    }
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__title {
    padding: 24px 60px 24px 30px;
    width: 100%;
    margin: 0;
    color: inherit;
    font-weight: 400;
    line-height: 1.55;
    position: relative;
    z-index: 3;
    .icon {
      position: absolute;
      right: 22px;
      width: 18px;
      height: 18px;
      bottom: 27px;
      transition: fill $trs1;
      fill: var(--color-block-services, #1C52AC);
    }
  }
  @include media-breakpoint-between(sm, xl) {
    &__title {
      font-size: 1rem;
    }
  }
  @include media-breakpoint-down(xl) {
    &__title {
      padding: 24px 60px 24px 24px;
    }
  }
}

.additionalLinks-section {
  padding: 40px 0;
  background-color: var(--color-sections-bg, #f5f5f5);
  .row {
    margin-bottom: -32px;
  }
  @include media-breakpoint-down(md) {
    padding: 32px 0;
  }
}

.additionalLinks-block {
  margin-bottom: 32px;
  &__container {
    overflow: hidden;
    background-color: #fff;
    position: relative;
    padding: 30px 70px 30px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    color: #000;
    border-radius: 2px;
    min-height: 200px;
    .background {
      will-change: transform;
      &_left {
        transform-origin: left;
      }
      &_right {
        transform-origin: right;
      }
    }
    &[data-touch], &[data-hover] {
      .background {
        transform: scale(1.025);
      }
    }
  }
  &__static-image {
    padding: 0 !important;
    overflow: hidden;
    &[data-touch], &[data-hover] {
      img {
        transform: scale(1.05);
      }
    }
  }
  &__background {
    background-size: auto 100%;
  }
  &__title {
    margin: 0;
    position: relative;
    z-index: 2;
  }
  &__text {
    margin: 0;
    position: relative;
    z-index: 2;
    margin-top: 12px;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  &__button {
    margin-top: 20px;
    position: relative;
    z-index: 2;
    font-size: 1.125rem;
  }
  &__image {
    width: 100%;
    height: auto;
  }
  @include media-breakpoint-down(xl) {
    &__container {
      padding: 30px 40px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__container {
      padding: 30px 20px;
    }
    &__text {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
  @include media-breakpoint-down(sm) {
    &__container {
      padding: 24px 16px;
    }
    &__button {
      margin-top: 24px;
      width: 100%;
      border: 1px solid var(--color-button1-border, #B7CBE5);
      color: var(--color-button1, #1C52AC);
      background-color: var(--color-button1-bg, transparent);;
      .icon {
        fill: var(--color-button1-icon, #2363D1);
      }
      &[data-touch], &[data-hover] {
        color: var(--color-button1-hover, #fff);
        background-color: var(--color-button1-hover-bg, #2363D1);
        border-color: var(--color-button1-hover-border, #2363D1);
        .icon {
          fill: var(--color-button1-hover-icon, #fff);
        }
      }
    }
    &__text {
      font-size: 0.75rem;
      line-height: inherit;
    }
  }
}

.section-articles {
}

.article-preview {
  &__label {
    display: inline-block;
    margin-bottom: 10px;
    padding: 0 12px;
    line-height: 24px;
    background-color: var(--color-label-article, #D9D9DE);
    color: var(--color-accent-darker, #1C52AC);
    font-size: 0.75rem;
  }
  &__container {
    border-bottom: 1px solid var(--color-border-1, #D9D9DE);
    padding-bottom: 20px;
  }
  &__text {
    color: inherit;
    text-decoration: none;
    transition: color $trs1;
    display: block;
    &[data-touch], &[data-hover] {
      color: var(--color-accent-darker, #1C52AC);
    }
  }
}