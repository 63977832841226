.mb--1 {
  margin-bottom: -16px;
}
.mb--2 {
  margin-bottom: -24px;
}

.mb-1 {
  margin-bottom: 16px;
}
.mb-2 {
  margin-bottom: 24px;
}

.color-text-1 {
  color: var(--color-card-accent-1, #C43228);
}
.color-text-2 {
  color: var(--color-card-accent-2, #4FA22E);
}
.color-text-3 {
  color: var(--color-card-accent-3, #FF9500);
}
.color-text-4 {
  color: var(--color-card-accent-4, #30C2FF);
}

.text-color-light {
  color: var(--color-text-light, #BDBDBD);
}

.text-color-accent {
  color: var(--color-accent, #2363D1);
}

.text-uppercase {
  text-transform: uppercase;
}

.font2 {
  font-family: $font2;
}