.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.main {
  flex: 1 1 100%;
  @include media-breakpoint-up(sm) {
    &_muted {
      background-color: var(--color-sections-bg, #F5F5F5);
    }
  }
}

.content {
  padding: 30px 0 0 0;
  display: flex;
  flex-direction: column;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
  transition: opacity $trs3, visibility $trs3, transform $trs1;
  &.lazyloaded {
    opacity: 1;
    visibility: visible;
  }
  &_left {
    background-position-x: left;
  }
  &_right {
    background-position-x: right;
  }
  &_top {
    background-position-y: top;
  }
  &_bottom {
    background-position-y: bottom;
  }
  &_contain {
    background-size: contain;
  }
}

.image {
  display: block;
  position: relative;
  img {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    margin: 0 !important;
  }
  &_contain, &_cover {
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
    }
  }
  &_cover {
    background-color: #D9D9DE;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }
  &_contain {
    img {
      object-fit: contain;
    }
  }
}

.section {
  position: relative;
  margin-bottom: 72px;
  & > .container {
    position: relative;
    z-index: 2;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
  }
  &_custom-background {
    padding: 72px 0;
    background-size: cover;
    background-position: center;
    & + .section_custom-background {
      margin-top: -72px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &_small-margin {
    margin-bottom: 60px;
  }
  &_small-padding {
    padding: 40px 0;
  }
  &__title {
    margin-top: 0;
    margin-bottom: 28px;
    span {
      margin-right: 1rem;
    }
  }
  &__more {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  &__load {
    margin-top: 32px;
    justify-content: center;
    &-button {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
    &_custom-background {
      padding: 60px 0;
      & + .section_custom-background {
        margin-top: -60px;
      }
    }
    &_no-margin {
      margin: 0 !important;
    }
    &_small-margin {
      margin-bottom: 40px;
    }
    &_padding {
      padding: 72px 0;
      & + .section_padding {
        margin-top: -132px;
      }
    }
    &_background-muted {
      background-color: var(--color-sections-bg, #F5F5F5)
    }
    &_background-white {
      background-color: #fff;
    }
  }
  @include media-breakpoint-up(lg) {
    &_padding {
      padding: 60px 0;
      & + .section_padding {
        margin-top: -120px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__more {
      margin-top: 32px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__background {
      img {
        object-position: center right;
      }
    }
    &_custom-background {
      background-position: center right;
    }
    &__more-button {
      width: 100%;
    }
    &__title {
      margin-bottom: 24px;
    }
  }
}

h1.section__title {
  margin-top: 0;
  margin-bottom: 32px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 28px;
  }
}

.page-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  span {
    &:first-child {
      margin-right: 16px;
    }
    &:last-child {
      &:not(:first-child) {
        display: inline-block;
        font-weight: normal;
        font-size: 0.75rem;
        color: var(--color-text-muted, #767676);
        transform: translateY(4px);
      }
    }
  }
}

.section .section {
  &:last-child {
    margin-bottom: 0;
  }
}

.background-box {
  padding: 60px 0;
  background-color: #fff;
  margin: 0;
}

.page-container {
  @include media-breakpoint-up(sm) {
    background-color: #fff;
    padding: 30px 20px;
    border: 1px solid var(--color-table-border, #E5E5EA);
    border-radius: 2px;
  }
  @include media-breakpoint-up(md) {
    padding: 40px 30px;
  }
  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 40px 100px;
  }
}

.display-container {
  @include media-breakpoint-up(sm) {
    background-color: #fff;
    padding: 30px 20px;
    border: 1px solid var(--color-table-border, #E5E5EA);
    border-radius: 2px;
  }
  @include media-breakpoint-up(md) {
    padding: 40px 30px;
  }
  @include media-breakpoint-up(lg) {
    padding: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 40px 50px;
  }
}

.grid {
  margin-bottom: -20px;
  &__block {
    margin-bottom: 20px;
  }
  &__head {
    margin-bottom: 35px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: -24px;
    &__block {
      margin-bottom: 24px;
    }
  }
}