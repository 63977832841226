@mixin font-face($font-family, $file-path, $weight:400, $style:normal, $asset-pipeline: false ) {
	@font-face {
		font-display: swap;
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;
		src: url('#{$file-path}.woff2') format('woff2'),
				url('#{$file-path}.woff') format("woff");
		
	}
}

@include font-face("Golos", "../fonts/Golos/Golos Text_Regular", 400);
@include font-face("Golos", "../fonts/Golos/Golos Text_Medium", 500);
@include font-face("Golos", "../fonts/Golos/Golos Text_DemiBold", 600);
@include font-face("Golos", "../fonts/Golos/Golos Text_Bold", 700);
@include font-face("Golos", "../fonts/Golos/Golos Text_Black", 800);