.tippy-box {
  background-color: #fff;
  color: var(--color-text-muted, #767676);
  border-radius: 2px;
  border: 1px solid var(--color-border-1, #D9D9DE);
  box-shadow: 0px 2px 25px rgba(118, 118, 118, 0.15);
  padding: 25px 40px;
}
.tippy-content {
  padding: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-arrow {
  &:before {
    display: block;
    width: 100%;
    height: 100%;
    border-color: var(--color-border-1, #D9D9DE);
    border-style: solid;
    background-color: #fff;
    left: 0 !important;
    top: 0 !important;
    transform-origin: center !important;
  }
}
.tippy-box[data-placement^=right]>.tippy-arrow {
  left: -1px;
  &::before {
    transform: translateX(-50%) rotate(-45deg);
    border-width: 1px 0 0 1px;
    border-radius: 2px 0 0 0;
  }
}

.tippy-box[data-placement^=top]>.tippy-arrow {
  bottom: -1px;
  &::before {
    transform: translateY(50%) rotate(-45deg);
    border-width: 0 0 1px 1px;
    border-radius: 0 0 2px 0;
  }
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
  top: -1px;
  &::before {
    transform: translateY(-50%) rotate(-45deg);
    border-width: 1px 1px 0 0;
    border-radius: 2px 0 0 0;
  }
}

.tippy-box[data-placement^=left]>.tippy-arrow {
  right: -1px;
  &::before {
    transform: translateX(50%) rotate(-45deg);
    border-width: 0 1px 1px 0;
    border-radius: 0 0 2px 0;
  }
}

@include media-breakpoint-down(sm) {
  .tippy-box {
    padding: 20px;
  }
}