.header {
  flex-shrink: 0;
  position: relative;
  z-index: 100;

  &_fixed {
    position: fixed;
    top: 0;
    width: 100%;
    transition: box-shadow .25s ease-out;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.15);
    animation: .25s ease-out 0s slideHeader;
  }

  &__top {
    background-color: var(--color-header-top, #fff);
    padding: 20px 0;
    width: 100%;
  }

  &__bottom {
    --padding-y: 15px;
    --search-height: 48px;
    --search-padding: 12px 16px;
    padding: var(--padding-y) 0;
  }

  &__logo {
    order: var(--logo-order);
  }
  
  &__contact-group {
    order: var(--contact-order);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__buttons-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &-nav {
    order: var(--nav-order);
    margin-left: var(--nav-margin-left);
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
    }
    &__item {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
    &__link {
      display: block;
      color: var(--color-header-links, #333333);
      text-decoration: none;
      transition: color $trs1;
      &[data-touch], &[data-hover] {
        color: var(--color-header-links-hover, #1C52AC);
      }
    }
  }
  &__contact-items {
    display: flex;
    align-items: center;
  }
  &__phones {
    line-height: 20px;
    margin-right: 20px;
    font-weight: 700;
    color: var(--color-header-phone, #333333);
  }
  &__phones-toggle {
    white-space: nowrap;
    position: relative;
    font-weight: inherit;
    color: inherit;
    transition: color $trs1;
    padding-left: 20px;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      animation: flicker 1s linear infinite;
    }
    &::before {
      width: 15px;
      height: 15px;
      left: 0;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      border-color: currentColor;
    }
    &::after {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      left: 5px;
      background-color: currentColor;
    }
    &[data-touch], &[data-hover] {
      color: var(--color-header-phone-hover, #1C52AC);
    }
    & ~ .header__phones-inner {
      display: none;
    }
  }
  &__phone {
    display: block;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    transition: color $trs1;
    &[data-touch], &[data-hover] {
      color: var(--color-header-phone-hover, #1C52AC);
    }
  }
  &__city {
    margin-right: 23px;
    position: relative;
    z-index: 3;
    &-link {
      color: var(--color-accent-darker, #1C52AC);
      text-decoration: none;
      transition: color $trs1;
      display: flex;
      align-items: center;
      .icon {
        width: 9px;
        height: 9px;
        fill: var(--color-accent, #2363D1);
        transition: fill $trs1;
        margin-left: 6px;
        display: inline-block;
      }
      &[data-touch], &[data-hover] {
        color: var(--color-accent, #2363D1);
        .icon {
          fill: var(--color-accent, #2363D1);
        }
      }
    }
  }
  &__small-button {
    text-decoration: none;
    color: var(--color-header-labels, #fff);
    transition: color $trs1;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    .icon {
      transition: fill $trs1;
      fill: var(--color-header-button-icon, #fff);
    }
    &[data-touch], &[data-hover] {
      .icon {
        fill: var(--color-header-button-hover-icon, #2363D1);
      }
    }
    &_auth {
      margin-right: 19px;
      .icon {
        width: 21px;
        height: 25px;
      }
      span {
        margin-left: 9px;
      }
    }
    &_comparison,
    &_cart {
      span {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
      }
    }
    &_comparison {
      margin-right: 26px;
      .icon {
        width: 27px;
        height: 25px;
      }
      span {
        margin-left: 6px;
      }
    }
    &_cart {
      .icon {
        width: 14px;
        height: 20px;
      }
      span {
        margin-left: 13px;
        color: #fff;
        background-color: var(--color-accent, #2363D1);
        border-radius: 24px;
        transition: background-color $trs1;
      }
      &[data-touch], &[data-hover] {
        span {
          background-color: transparent;
        }
      }
    }
  }
  &__search {
    grid-column: var(--search-grid-column);
    .search__button {
      height: var(--search-height);
      flex: 0 0 80px;
    }
    .search__input {
      transition: color $trs1, background-color $trs1, border-color $trs1;
      padding: var(--search-padding);
    }
    .search__button {
      background-color: var(--color-header-search-button-bg, #333333);
    }
    &.active {
      .search__button {
        background-color: var(--color-header-search-active-button-bg, #2363D1);
        &[data-touch], &[data-hover] {
          background-color: var(--color-header-search-active-button-hover-bg, #1C52AC);
        }
      }
      .search__input {
        border-color: var(--color-header-search-active-border, #2363D1);
      }
    }
  }

  &:not(.header_fixed) {
    padding-right: 0 !important;
    .header__search {
      .search__form {
        background-color: var(--color-header-search-bg, #222222);
      }
      .search__input {
        border-color: var(--color-header-search-border, #333333);
        color: var(--color-header-search, #fff);  
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      &.active {
        .search__input {
          color: var(--color-header-search-active, #333333);
          background-color: var(--color-header-search-active-bg, #fff);
          border-color: var(--color-header-search-active-border, #2363D1);
        }
      }
    }
    .header__bottom {
      padding-right: 0 !important;
      background-color: var(--color-header-bottom, #000);
      &_transparent {
        background-color: transparent;
        position: absolute;
        width: 100%;
        top: 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__top, &__bottom {
      .container {
        display: grid;
        align-items: center;
        justify-content: space-between;
        grid-template-columns: var(--container-grid);
      }
    }
    &__top {
      --nav-margin-left: 40px;
      --logo-order: 1;
      --nav-order: 2;
      --contact-order: 3;
      --container-grid: auto 1fr auto;
    }
    &__bottom {
      --container-grid: 1fr auto;
      --search-grid-column: 1/2;
    }
    &__buttons-group {
      margin-left: 40px;
    }
    &_fixed {
      .header__bottom {
        display: none;
      }
      .header__top {
        padding: 15px 0;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &__top {
      --nav-margin-left: 45px;
    }
    &__bottom {
      --container-grid: 700px auto;
    }
    &_fixed {
      .header__search {
        width: 500px;
      }
    }
    &:not(.header_fixed) {
      .header__top {
        &_type-2 {
          --nav-margin-left: 0;
          .header-nav {
            margin-left: 0;
            &__list {
              justify-content: center;
            }
          }
        }
        &_type-3 {
          --logo-order: 2;
          --nav-order: 1;
          --nav-margin-left: 0;
          --container-grid: 1fr auto 1fr;
        }
      }
      .header__bottom {
        &_type-2 {
          --container-grid: 310px auto;
        }
        &_type-3 {
          --padding-y: 5px;
          --container-grid: 500px auto;
          --search-height: 40px;
          --search-padding: 9px 16px;
        }
        &_type-4 {
          --container-grid: 1fr 310px 1fr;
          --search-grid-column: 2/3;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    &__phones {
      display: none;
    }
  }
  @include media-breakpoint-down(lg) {
    &_fixed {
      .header__search {
        display: none;
      }
      .header__bottom {
        padding: 0;
      }
      .header__buttons-group {
        top: -15px;
      }
    }

    &__top {
      padding: 15px 0;
      .container {
        display: flex;
        justify-content: space-between;
      }
    }
    &__bottom .container {
      position: relative;
    }
    &-nav {
      display: none;
    }
    
    &__search {
      .search__input {
        background-color: var(--color-header-search-bg-mobile, #333333);
      } 
    }
    .logo__link {
      height: 30px;
      width: 170px;
    }
    &__buttons-group {
      position: absolute;
      right: 62px;
      top: -30px;
      transform: translateY(-100%);
      margin: 0;
    }
    &__contact-items {
      display: none;
    }
    &__city {
      display: none;
    }
    &__small-button {
      padding: 5px;
      span {
        font-size: 0.5rem;
      }
      &_auth {
        display: none;
      }
      &_cart,
      &_comparison {
        align-items: flex-start;
        .icon {
          fill: var(--color-header-button-icon-mobile, #333333);
        }
        span {
          width: 16px;
          height: 16px;
          line-height: 16px;
          margin-left: 5px;
          transform: translateY(-5px);
        }
      }
      &_comparison {
        color: var(--color-header-button-icon-mobile, #333333);
        margin-right: 16px;
        .icon {
          height: 20px;
          width: 18px;
        }
      }
      &_cart {
        &[data-touch], &[data-hover] {
          color: var(--color-header-button-icon-mobile, #333333);
        }
      }
    }
  }
  
  @include media-breakpoint-down(sm) {
    &_fixed {
      .header__buttons-group {
        top: -10px;
      }
    }
    &__top {
      padding: 10px 0;
    }
    .logo__link {
      width: 117px;
    }
    &__buttons-group {
      right: 56px;
      top: -25px;
    }
    &__small-button_comparison {
      margin-right: 12px;
    }
  }
}

.nav-toggle {
  display: none;
  width: 30px;
  height: 30px;
  padding: 7px 6px;
  flex-direction: column;
  justify-content: space-between;
  span {
    width: 100%;
    height: 2px;
    display: block;
    background-color: var(--color-header-button-icon-mobile, #333333);
    transition: background-color $trs1, transform $trs1, opacity $trs1;
  }
  &[data-touch], &[data-hover] {
    span {
      background-color: var(--color-header-button-hover-icon, #2363D1);
    }
  }
  &.active {
    span {
      &:nth-child(2) {
        opacity: 0;
      }
      &:first-child {
        transform: translateY(7px) rotate(-45deg) scaleX(1.15) ;
      }
      &:last-child {
        transform: translateY(-7px) rotate(45deg) scaleX(1.15);
      }
    }
  }
  @include media-breakpoint-down(lg) {
    display: flex;
  }
}

@keyframes slideHeader { 
  from { 
    transform: translateY(-100%);
    box-shadow: none;
  } 
  to {
    transform: translateY(0);
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.15);
  }
}

@keyframes flicker {
  0% {
    color: #fff;
  }
  80% {
    color: var(--color-accent);
  }
  100% {
    color: #fff;
  }
}