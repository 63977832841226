.mobile-nav {
  opacity: 0;
  visibility: hidden;
  font-size: 1.125rem;
  position: fixed;
  top: 60px;
  height: calc(100% - 60px);
  background-color: var(--color-sections-bg, #F5F5F5);
  width: 100%;
  z-index: 110;
  transition: opacity $trs1, visibility $trs1;
  display: none;
  &.scrollbar {
    &:before {
      background: linear-gradient(0deg,rgba(255,255,255,0) 0, var(--color-sections-bg, #F5F5F5) 100%);
    }
    &:after {
      background: linear-gradient(180deg,rgba(255,255,255,0) 0, var(--color-sections-bg, #F5F5F5) 100%);
    }
  }
  .search-content-window {
    max-height: 258px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__content {
    padding: 16px 0 0 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__search {
    margin-top: 6px;
    position: relative;
    z-index: 5;
  }
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  &__dropdown-content {
    &_style-1 {
      .mobile-nav__sub-nav-container {
        padding: 20px 0 25px 0;
      }
      .mobile-nav__sub-nav-list {
        padding: 24px 40px 24px 40px;
      }
    }
    &_style-2 {
      .mobile-nav__sub-nav-list {
        padding-top: 24px;
        padding-bottom: 24px;
        
      }
      .mobile-nav__sub-nav-container {
        padding-top: 20px;
      }
    }
  }
  &__sub-nav {
    &-list {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      background-color: var(--color-border-1, #D9D9DE);
    }
    &-item {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
    &-link {
      color: inherit;
      transition: color $trs1;
      text-decoration: none;
      position: relative;
      display: block;
      font-size: 0.875rem;
      padding: 5px 30px 5px 0;
      span {
        display: block;
      }
      .icon {
        fill: var(--color-accent, #2363D1);
        position: absolute;
        transition: opacity $trs1;
      }
      &[data-touch], &[data-hover] {
        color: var(--color-accent-darker, #1C52AC);
      }
      &_style-1 {
        font-size: 1.125rem;
        .icon {
          top: 9px;
          right: 5px;
          opacity: 0;
        }
        &.active {
          user-select: none;
          .icon {
            opacity: 1;
          }
        }
      }
      &_style-2 {
        .icon {
          top: 8px;
          right: 4px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &__ico-link {
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--color-accent-darker, #1C52AC);
    line-height: 32px;
    padding-left: 42px;
    .icon {
      position: absolute;
      transition: fill $trs1, transform $trs1;
      &:first-child {
        height: 32px;
        width: 24px;
        left: 0;
        top: 0;
        fill: var(--color-border-1, #D9D9DE);
      }
      &:last-child {
        right: 5px;
        top: 11px;
        width: 12px;
        height: 12px;
        fill: var(--color-accent, #2363D1);
      }
    }
    &[data-touch], &[data-hover], &.active {
      .icon {
        &:first-child {
          fill: var(--color-accent, #2363D1);
        }
      }
    }
    &.active {
      .icon {
        &:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__top-item {
    margin-bottom: 16px;
  }
  &__top-item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__search {
    margin-bottom: 24px;
  }
  &__top-section {
    margin-bottom: 35px;
  }
  &__contact-section {
    border-top: 1px solid #fff;
    padding: 22px 0 32px 0;
  }
  &__contact-button {
    margin-top: 32px;
    .button {
      font-size: 1.125rem;
    }
  }
  &__contact-phone  {
    color: var(--color-accent-darker, #1C52AC);
    font-size: 1.375rem;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
    padding-left: 42px;
    position: relative;
    line-height: 28px;
    .icon {
      position: absolute;
      left: 4px;
      top: 0;
      width: 24px;
      height: 28px;
      top: 0;
      fill: var(--color-border-1, #D9D9DE);
      transition: fill $trs1;
    }
    &[data-touch], &[data-hover] {
      .icon {
        fill: var(--color-accent, #2363D1);
      }
    }
  }
  &__nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__nav-item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__nav-link {
    display: block;
    text-decoration: none;
    transition: color $trs1;
    color: inherit;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    transition: color $trs1;
    .icon {
      width: 12px;
      height: 12px;
      fill: var(--color-accent, #2363d1);
      margin-left: 19px;
      transition: transform $trs1;
    }
    &[data-touch], &[data-hover] {
      color: var(--color-accent-darker, #1C52AC);
    }
    &.active {
      .icon {
        transform: rotate(180deg);
      }
    }
  }


  @include media-breakpoint-down(lg) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    top: 50px;
    height: calc(100% - 50px);
    &__contact-button .button {
      width: 100%;
    }
    &__dropdown-content {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }
}