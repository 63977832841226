html {
  font-size: 16px;
}

body {
  font-family: 'Golos', sans-serif;
  text-rendering: optimizeSpeed;
  color: var(--color-text, #333333);
  font-size: 0.875rem;
  line-height: 1.25rem;
  min-width: 320px;
}

* {
  box-sizing: border-box;
  min-height: 0px;
  min-width: 0px;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  &:before, &:after {
    box-sizing: border-box;
  }
}

a, button, input, textarea, select, label {
  touch-action: manipulation;
}

button, input, textarea, select {
  outline: none;
  display: block;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  appearance: none;
  padding: 0;
  border-radius: 0;
  color: inherit;
  font-weight: inherit;
  text-rendering: inherit;
  line-height: inherit;
}

a, button, label, [data-touch-hover] {
  user-select: none;
  cursor: pointer;
  * {
    user-select: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input {
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s;
  }
}

textarea {
  resize: none;
}

table {
  border-collapse: collapse;
}

img {
  display: block;
  user-select: none;
}