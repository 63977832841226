.logo {
  &__link {
    display: block;
    width: 200px;
  }
  img, svg {
    display: block;
    object-fit: contain;
    object-position: left center;
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
  svg {
    fill: var(--color-header-logo, #000);
  }
}

.card-labels {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.card-label {
  display: block;
  width: auto;
  min-width: 50px;
  font-size: 12px;
  padding: 0 5px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  position: relative;
  &_style-1 {
    background-color: var(--color-label-sale, #FF9500);
  }
  &_style-2 {
    background-color: var(--color-label-new, #30C2FF);
  }
  &_style-3 {
    background-color: var(--color-label-hit, #3EC28F);
  }
  &_style-4 {
    background-color: var(--color-label-stock, #D74853);
  }

  &_style-new {
    background-color: var(--color-label-new, #30C2FF);
  }
  &_style-sale {
    background-color: var(--color-label-sale, #FF9500);
  }
  &_style-hit {
    background-color: var(--color-label-hit, #3EC28F);
  }
  &_style-stock {
    background-color: var(--color-label-stock, #D74853);
  }
}

.category-card {
  font-size: 0.875rem;
  line-height: 1.25rem;
  &__container {
    min-height: 140px;
    color: var(--color-small-card-text, #fff);
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    height: 100%;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-category-block, rgba(0, 0, 0, 0.6));
      transition: background-color $trs1;
      z-index: 2;
    }
    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: transparent;
      &_contain {
        z-index: 3;
        opacity: 0.2;
        img {
          width: 45px;
          height: 45px;
          top: 30px;
          left: 30px;
        }
      }
    }
    &[data-touch], &[data-hover] {
      &:after {
        background-color: var(--color-category-block-hover, rgba(28, 82, 172, 0.75));
      }
    }
  } 
  &__title {
    padding: 20px 40px 20px 20px;
    width: 100%;
    z-index: 4;
    margin: 0;
    color: inherit;
    position: relative;
    font-size: inherit;
    line-height: inherit;
    .icon {
      position: absolute;
      right: 15px;
      width: 16px;
      height: 16px;
      bottom: 22px;
      fill: currentColor;
    }
  }
  img {
    filter: grayscale(100%);
  }
  @include media-breakpoint-down(sm) {
    &__container {
      height: 180px;
    }
  }
}

.video-frame {
  width: 100%;
  max-width: 565px;
  background-color: #C4C4C4;
  position: relative;
  display: flex;
  span {
    margin: auto;
  }
  &::before {
    content: '';
    display: block;
    padding-top: 43%;
  }
}

.news-preview-block {
  &__container {
    border-radius: 2px;
    overflow: hidden;
    min-height: 220px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;
    color: #fff;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      z-index: 2;
      transition: opacity $trs1;
    }
    &[data-touch], &[data-hover] {
      &:before {
        opacity: 0.5;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 3;
    padding: 28px 38px;
  }
  &__label {
    display: block;
    max-width: 80px;
    line-height: 24px;
    border-radius: 24px;
    color: #fff;
    text-align: center;
    font-size: 0.75rem;
    margin-bottom: 14px;
    &_style-1 {
      background-color: var(--color-label-stock, #D74853);
    }
    &_style-2 {
      background-color: var(--color-label-news, #3EC28F);
    }
  }
  @include media-breakpoint-down(md) {
    &__content {
      padding: 28px 15px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__text {
      font-size: 0.75rem;
    }
    &__label {
      margin-bottom: 12px;
    }
  }
}

.news-large-block {
  &__container {
    display: flex;
    min-height: 300px;
  }
  &__label {
    display: block;
    width: 120px;
    line-height: 40px;
    border-radius: 40px;
    color: #fff;
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 25px;
    &_style-1 {
      background-color: var(--color-label-stock, #D74853);
    }
    &_style-2 {
      background-color: var(--color-label-news, #3EC28F);
    }
  }
  &__title {
    margin: 0 0 25px 0;
  }
  &__image {
    flex: 1 1 45%;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      transition: opacity $trs1;
      opacity: 0.3;
      z-index: 2;
      will-change: opacity $trs1;
    }
    &[data-touch], &[data-hover] {
      &:before {
        opacity: 0.5;
      }
    }
  }
  &__content {
    flex: 1 1 55%;
    background-color: var(--color-sections-bg, #F5F5F5);
    padding: 35px 100px 35px 60px;
  }
  @include media-breakpoint-down(xl) {
    &__content {
      padding: 35px 40px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__content {
      padding: 35px;
      flex: 1 1 50%;
    }
    &__title {
      font-size: 1.25rem;
    }
    &__image {
      flex: 0 0 calc(50% - 10px);
    }
  }
  @include media-breakpoint-down(md) {
    &__container {
      flex-direction: column;
      flex: 0 0 auto;
    }
    &__label {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      z-index: 5;
    }
    &__image {
      flex: 0 0 auto;
      height: 180px;
    }
    &__content {
      position: relative;
      padding: 45px 20px 30px 20px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__container {
      margin-left: -16px;
      margin-right: -16px;
    }
    &__content {
      padding: 45px 16px 30px 16px;
    }
    &__title {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    &__button {
      width: 100%;
    }
  }

}

.tabs {
  &__block {
    display: none;
  }
}

.card-price {
  &__title {
    display: none;
    color: var(--color-text-light,#bdbdbd);
    font-size: .75rem;
    line-height: 1rem;
  }
  &__label {
    display: block;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 5px;
    &_style-1 {
      color: var(--color-card-accent-1, #C43228);
    }
    &_style-2 {
      color: var(--color-card-accent-2, #4FA22E);
    }
    &_style-3 {
      color: var(--color-card-accent-3, #FF9500);
    }
    &_style-4 {
      color: var(--color-card-accent-4, #30C2FF);
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__smaller-price-unit {
    display: none;
  }
  &__no-price {
    height: 20px;
  }
  &__current {
    font-weight: 700;
    color: #000;
    font-size: 1.125rem;
    white-space: nowrap;
    line-height: 1.5rem;
    height: 1.5rem;
    margin-right: 8px;
    flex-shrink: 0;
    span {
      font-size: 0.8em;
    }
  }
  &__old {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--color-text-muted, #767676);
    position: relative;
    white-space: nowrap;
    flex-shrink: 0;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 100%;
      background-color: #D74853;
    }
  }
}

.advertising-preview {
  background-color: var(--color-border-1, #D9D9DE);
  border-radius: 2px;
  width: 100%;
  height: 380px;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  img {
    will-change: transform;
  }
  &[data-touch], &[data-hover] {
    img {
      transform: scale(1.05);
    }
  }
  @include media-breakpoint-down(xl) {
    height: auto;
    &:before {
      content: '';
      display: block;
      padding-top: 173%;
    }
  }
}

.popular-projects {
  &__advertising {
    padding-top: 56px;
  }
  &__more {
    justify-content: flex-end;
  }
  &__slider {
    &_type-2 {
      .slick-slide {
        display: grid;
        grid-auto-rows: 1fr;
      }
    }
  }
  &__slider-2 {
    .slick-slide {
      display: grid;
      grid-auto-rows: 1fr;
    }
  }
  @include media-breakpoint-up(sm) {
    &__slider {
      opacity: 1;
      visibility: visible; 
    }
  }
  @include media-breakpoint-down(lg) {
    &__advertising {
      display: none;
    } 
  }
}

.project-preview-card {
  &__container {
    border: 1px solid var(--color-border-1, #D9D9DE);
    border-radius: 2px;
    overflow: hidden;
    height: 100%;
    min-height: 380px;
    display: flex;
    flex-direction: column;
  }
  &__image {
    flex: 0 0 257px;
    max-height: 257px;
    position: relative;
    overflow: hidden;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: transform $trs1;
      will-change: transform;
      &_contain {
        background-color: var(--color-border-1, #D9D9DE);
        img {
          padding: 45px;
        }
      }
    }
    &[data-touch], &[data-hover] {
      .image {
        transform: scale(1.05);
      }
    }
  }
  &__status {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    background-color: #fff;
    color: #999999;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    padding: 0 5px;
    line-height: 18px;
  }
  &__index {
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    font-size: 0.75rem;
    color: #fff;
    padding: 13px;
  }
  &__content {
    flex: 1 1 100%;
    position: relative;
    padding: 10px 13px 15px 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-sections-bg, #F5F5F5);
  }
  &__title {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: color $trs1;
    margin-right: 120px;
    &[data-touch], &[data-hover] {
      color: var(--color-accent, #2363D1);
    }
  }
  &__rating {
    position: absolute;
    top: 0;
    right: 0;
    width: 85px;
    user-select: none;
    pointer-events: none;
  }
  &__buttons {
    display: flex;
    .button {
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
  &__content-bottom {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
  }
  &__content-top {
    position: relative;
    flex: 1 1 100%;
  }
  @include media-breakpoint-down(xl) {
    &__content {
      padding: 12px 12px 15px 12px;
    }
    &__content-bottom {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 12px;
    }
    &__buttons {
      order: 2;
    }
    &__rating {
      top: calc(100% + 14px);
    }
    &__title {
      margin: 0;
    }
    &__buttons {
      width: 100%;
      .button {
        &:not(:last-child) {
          margin-right: 12px;
          flex: 1 1 100%;
          max-width: 100%;
        }
      }
    }
    .card-price {
      order: 1;
      margin-bottom: 15px;
    }
  }
}

.price-chart {
  &__head {
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(xl) {
    &__content {
      margin: 0 -14px 0 -40px;
    }
  }
}

.specifications {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  &__title {
    margin: 0 0 20px 0;
  }
  &__table {
    width: 100%;
    span {
      background-color: #fff;
      position: relative;
      z-index: 2;
    }
    tr {
      &:not(:last-child) {
        td {
          padding-bottom: 1rem;
        }
      }
    }
    td {
      position: relative;
      vertical-align: top;
      &:first-child {
        width: 60%;
        span {
          padding-right: 1rem;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          top: 0.875rem;
          left: 0;
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMCIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNiAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2IDI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48Y2lyY2xlIHN0eWxlPSJmaWxsOiMyMzYzRDE7IiBjeD0iMSIgY3k9IjEiIHI9IjEiLz48L2c+PC9zdmc+);
        }
      }
      &:last-child {
        padding-left: 16px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__table {
      width: 100%;
      tr {
        display: block;
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      td {
        display: block;
        padding: 0 !important;
        &:first-child {
          color: var(--color-text-light, #BDBDBD);
          margin-bottom: 4px;
          width: 100%;
          font-size: 0.875rem;
          &::before {
            display: none;
          }
        }
        &:last-child {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }
      }
    }
  }
} 


.section-partners {
  &__title {
    margin-bottom: 20px;
    text-align: center;
  }
  &__text {
    text-align: center;
    font-size: 1.125rem;
  }
  &__slider {
    margin-top: 25px;
    .slick-list {
      margin: 0 -20px;
    }
    .slider__prev,
    .slider__next {
      top: 50%;
      transform: translateY(-50%);
    } 
    .slider__prev {
      left: -95px;
    }
    .slider__next {
      right: -95px;
    }
  }
  &__slide {
    padding: 0 20px;
  }
  &__image {
    height: 52px;
  }
  @media screen and(max-width: 1439px) {
    &__slider {
        padding: 0 95px;
        .slider__next {
          right: 0;
        }
        .slider__prev {
          left: 0;
        }
      }
  }
}

.search-content-window {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  position: absolute !important;
  top: 100%;
  left: 0;
  transition: opacity $trs1, visibility $trs1;
  background-color: #fff;
  border-radius: 0 0 2px 2px;
  border-color: var(--color-border-1, #D9D9DE);
  border-style: solid;
  border-width: 0 1px 1px 1px;
  max-height: 400px;
  box-shadow: 0px 2px 25px rgba(118, 118, 118, 0.15);
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    padding: 12px 20px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-border-1, #D9D9DE);
    }
    &-title {
      line-height: 1.25rem;
      max-width: 480px;
      font-size: 0.875rem;
    }
    &-value {
      font-size: 1.125rem;
      font-size: #000;
      font-weight: 700;
      display: block;
      margin-top: 10px;
    }
    &-label {
      display: inline-block;
      border-radius: 2px;
      padding: 0 7px;
      font-size: 0.75rem;
      margin-left: 3px;
      &_style-1 {
        background-color: var(--color-text-muted, #767676);
        color: #fff;
      }
      &_style-2 {
        background-color: var(--color-text-muted-2, #E5E5EA);
        color: inherit;
      }
    }
  }
}

.search {
  position: relative;
  &__form {
    display: block;
    display: flex;
    width: 100%;
    border-radius: 2px;
    background-color: #fff;
  }
  &__button {
    background-color: var(--color-button2-bg, #2363D1);
    height: 40px;
    flex: 0 0 48px;
    display: flex;
    transition: background-color $trs1;
    pointer-events: none;
    border-radius: 0 2px 2px 0;
    &[data-touch], &[data-hover] {
      background-color: var(--color-header-search-active-button-hover-bg, #1C52AC);
    }
    .icon {
      margin: auto;
      width: 20px;
      height: 20px;
      fill: #fff;
    }
  }
  &__input { 
    border-color: var(--color-border-3, #A7A7AB);
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-radius: 2px 0 0 2px;
    font-size: 0.875rem;
    flex: 1 1 100%;
    padding: 9px 15px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder {
      color: var(--color-text-muted, #767676);
      transition: opacity $trs1;
    }
    &:focus {
      &::placeholder {
        opacity: 0 !important;
      }
    }
  }
  &.active {
    .search__button {
      pointer-events: all;
    }
  }
  &.active-content {
    .search__content {
      opacity: 1;
      visibility: visible;
    }
    .search__button {
      border-radius: 0 2px 0 0;
    }
    .search__input {
      border-radius: 2px 0 0 0;
    }
    .header-search__form {
      border-radius: 2px 2px 0 0;
    }
  }
}

.aside-categories {
  position: relative;
  z-index: 10;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &__button {
    width: 100%;
    line-height: 46px;
    border: 1px solid var(--color-border-1, #D9D9DE);
    background-color: #fff;
    height: auto;
    color: inherit;
    position: relative;
    z-index: 2;
    justify-content: flex-start;
    padding: 0 25px;
    border-radius: 2px;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 13px;
      fill: var(--color-accent, #2363D1)
    }
    &.active {
      border-radius: 2px 2px 0 0;
    }
  }
  &__content {
    box-shadow: 0px 2px 25px rgba(118, 118, 118, 0.15);
    border-style: solid;
    border-radius: 2px;
    border-width: 1px;
    border-color: var(--color-border-1, #D9D9DE);
    background-color: #fff;
  }
  &.toggle-section {
    .aside-categories__content {
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 2px 2px;
      z-index: 1;
      position: absolute;
      top: 100%;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity $trs1, visibility $trs1;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__list {
    margin: 0;
    padding: 10px 0;
    list-style: none;
  }
  &__link {
    display: block;
    text-decoration: none;
    color: #000;
    line-height: 18px;
    padding: 12px 40px 12px 16px;
    position: relative;
    background-color: transparent;
    transition: background-color $trs1;
    font-size: 0.875rem;
    .icon {
      height: 14px;
      width: 14px;
      top: 14px;
      right: 16px;
      position: absolute;
      display: block;
      fill: var(--color-accent, #2363D1)
    }
    &[data-touch], &[data-hover] {
      background-color: var(--color-sections-bg, #F5F5F5);
    }
    &_style-2 {
      font-weight: 700;
      color: #000;
      font-size: 1rem;
      line-height: 1.375rem;
      .icon {
        width: 16px;
        height: 16px;
        top: 16px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.toggle-section {
      &.aside-categories_desktop-visible {
        .aside-categories__button {
          pointer-events: none;
          border-radius: 2px 2px 0 0;
        }
        .aside-categories__content {
          opacity: 1;
          visibility: visible;
          position: relative;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    &__button {
      pointer-events: all;
      border-radius: 2px;
      justify-content: center;
    }
    &__link {
      padding-right: 60px;
      .icon {
        right: 35px;
      }
    }
  }
}

.sorting-heading {
  margin-bottom: 24px;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  &__download-button {
    margin-right: 34px;
  }
  &__items {
    display: flex;
    align-items: center;
    order: 2;
  }
  &__title {
    color: var(--color-text-muted, #767676);
    opacity: 0.75;
  }
  @include media-breakpoint-down(xl) {
    flex-direction: row;
    &__items {
      order: 0;
      margin-bottom: 15px;
    }
    &__download-button {
      order: 2;
      margin: 0 0 0 30px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__items {
      margin-bottom: 0;
      align-items: flex-end;
      flex: 0 0 auto;
      padding-left: 20px;
      order: 2;
    }
    &__download-button {
      margin: 0 0 0 20px;
      flex: 1 1 100%;
    }
  }
  @include media-breakpoint-down(md) {
    &__container {
      display: block;
    }
    &__items {
      padding: 0;
      margin-bottom: 20px;
      order: 0;
    }
    &__download-button {
      margin: 0;
    }
  }
}

.line-sorting {
  flex: 0 0 auto;
  &__container {
    display: flex;
    align-items: center;
  }
  &__title {
    margin-right: 20px;
  }
  &__buttons {
    display: flex;
  }
  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  &__link {
    color: var(--color-accent, #2363D1);
    transition: color $trs1;
    text-decoration: none;
    display: flex;
    align-items: center;
    .icon {
      fill: var(--color-accent, #2363D1);
      transition: fill $trs1;
      margin-right: 8px;
      width: 12px;
      height: 12px;
    }
    &[data-touch], &[data-hover], &.active {
      color: var(--color-accent-darker, #1C52AC);
      .icon {
        fill: var(--color-accent-darker, #1C52AC);
      }
    }
    &.active {
      font-weight: 700;
      pointer-events: none;
    }
  }
  &__view-toggle {
    width: 40px;
    padding: 0;
    min-width: 40px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.active {
      background-color: var(--color-button2-bg, #2363D1);
      border-color: var(--color-button2-bg, #2363D1);
      pointer-events: none;
      .icon {
        fill: var(--color-button2-icon, #fff);
        stroke: var(--color-button2-icon, #fff);
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__container {
      display: block;
    }
    &__title {
      display: block;
      margin: 0 0 8px 0;
    }
  }
}

.select-sorting {
  &__button {
    width: 100%;
  }
  &__title {
    display: block;
    margin: 0 0 8px 0;
  }
  @include media-breakpoint-down(lg) {
    flex: 1 1 50%;
  }
  @include media-breakpoint-down(sm) {
    &__button {
      width: 40px;
      min-width: 40px;
      padding: 0;
      .icon {
        margin: 0 !important;
      }
      span {
        display: none;
      }
    }
  }
}

.service-item-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
  &__price {
    font-size: 1.875rem;
    font-weight: 700;
    color: #000;
    line-height: 1.2;
    span {
      font-size: 0.6em;
    }
  }
  &__old-price {
    color: var(--color-old-price, #C43228);
    margin-left: 28px;
    font-size: 0.875rem;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -3px;
      width: calc(100% + 6px);
      height: 1px;
      background-color: var(--color-old-price, #C43228);;
    }
  }
  &__pricing {
    display: flex;
    align-items: center;
    margin: 0 60px 15px 0;
  }
  &__buttons {
    .button {
      margin-bottom: 15px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__buttons {
      .button {
        width: 100%;
      }
    }
  }
}

.map-section {
  &__map {
    width: 100%;
  }
}

.pagination {
  margin: 30px 0 0 0;
  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: flex-end;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  &__link {
    display: block;
    text-decoration: none;
    line-height: 44px;
    height: 48px;
    width: 48px;
    text-align: center;
    color: var(--color-pagination-accent, #2363D1);
    border-style: solid;
    font-size: 1.125rem;
    border-width: 2px;
    border-radius: 2px;
    border-color: transparent;
    transition: background-color $trs1, border-color $trs1, color $trs1;
    &[data-touch], &[data-hover], &.active {
      border-color: var(--color-pagination-accent, #2363D1);
    }
    &.active {
      pointer-events: none;
      background-color: var(--color-pagination-accent, #2363D1);
      color: #fff;
    }
  }
  @include media-breakpoint-up(md) {
    &_left {
      .pagination__list {
        justify-content: flex-start;
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 24px;
    &__list {
      justify-content: center;
    }
  }
}

.model-block {
  font-size: 0.875rem;
  &_xl {
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
      line-height: 1.5rem;
      .model-block {
        &__container {
          height: 440px;
          padding: 30px;
        }
        &__content {
          max-width: 420px;
        }
        &__button .icon {
          height: 24px;
          width: 8px;
          top: 2px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .model-block {
        &__container {
          min-height: 420px;
          height: 100%;
        }
      }
    }
  }
  &__container {
    display: block;
    padding: 15px 20px;
    height: 220px;
    border: 1px solid var(--color-border-1, #D9D9DE);
    border-radius: 2px;
    overflow: hidden;
    color: #fff;
    text-decoration: none;
    img {
      z-index: 1;
    }
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: opacity $trs1;
    }
    &:before {
      opacity: 1;
      background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0) 74.61%);
    }
    &:after {
      opacity: 0;
      background: linear-gradient(0, #2363D1 7.58%, rgba(35, 99, 209, 0.2) 112%);
    }
    &[data-touch], &[data-hover] {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
    color: #fff;
    height: 100%;
    max-width: 240px;
  }
  &__label {
    margin-bottom: 20px;
    min-width: 64px;
    width: auto;
    padding: 0 12px;
    text-align: left;
  }
  &__title {
    margin: 0 0 20px 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  &__button {
    position: relative;
    padding-right: 20px;
    .icon {
      fill: #fff;
      width: 7px;
      height: 20px;
      margin-left: 10px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    &__container {
      padding: 24px 12px;
      height: 250px;
    }
  }
}

.socials {
  &__list {
    margin: 0 -10px -10px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    margin: 0 10px 10px 0;
  }
  &__link {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    transition: background-color $trs1;
    background-color: var(--color-socials-bg, #2363D1);
    .icon {
      width: 18px;
      height: 18px;
      margin: auto;
      fill: #fff;
    }
    &[data-touch], &[data-hover] {
      background-color: var(--color-socials-hover-bg, #1C52AC);
    }
  }
}

.project-head {
  &__review-line {
    margin-bottom: 30px;
  }
  &-info {
    padding: 24px 20px;
    border: 3px solid var(--color-accent, #2363D1);
    &__list {
      margin: 1.875rem 0 0 0;
      padding: 0;
      list-style: none;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        bottom: 5px;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMCIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNCAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0IDI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48Y2lyY2xlIGN4PSIxIiBjeT0iMSIgcj0iMSIvPjwvZz48L3N2Zz4=);
      }
    }
    &__item-head {
      position: relative;
      line-height: 20px;
      padding-left: 30px;
      position: relative;
      z-index: 2;
      background-color: #fff;
      padding-right: 6px;
      .icon {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        stroke: var(--color-accent, #2363D1);
      }
    }
    &__item-text {
      font-weight: 700;
      position: relative;
      z-index: 2;
      background-color: #fff;
      padding-left: 1px;
    }
  }
  &__center {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
  &__image {
    position: relative;
  }
  &__photo-slider {
    .image {
      height: auto;
      &::before {
        content: '';
        display: block;
        padding-top: 63%;
      }
    }
  }
  &__card-price {
    margin-bottom: 15px;
    .card-price {
      &__label {
        margin-bottom: 14px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &__current {
        font-size: 1.375rem;
      }
      &__old {
        margin: 2px 0 0 0;
        color: var(--color-card-accent-1, #C43228);
        font-size: 0.875rem;
      }
    }
  }
  &__button {
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(lg) {
    &-info {
      &__item {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    &__info {
      margin-top: 40px;
    }
    &__center {
      padding-right: 10px;
    }
    &__button {
      width: 100%;
    }
    &__actions {
      justify-content: space-between;
    }
    &-info {
      &__list {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
      }
      &__item {
        margin: 0 15px !important;
        flex: 1 1 100%;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__center {
      padding-top: 0;
    }
    &__content {
      align-items: center;
    }
    &__info {
      margin-top: 30px;
    }
    &-info {
      &__list {
        margin: 0;
        display: block;
      }
      &__item {
        margin: 0 !important;
        &:not(:last-child) {
          margin-bottom: 20px !important;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &__image {
      margin-bottom: 12px;
    }
    &__center {
      padding-left: 10px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__center {
      padding: 0 8px;
    }
    &__card-price .card-price__old {
      margin: 0;
    }
    &__card-price .card-price__content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__review-line {
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }
}

.product-links {
  display: flex;
  justify-content: space-between;
  &__share {
    position: relative;
    z-index: 10;
    .button {
      .icon {
        height: 21px;
        width: 14px;
      }
    }
  }
  &__share-popup {
    right: 59px;
    margin-top: 15px;
  }
  &__item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__share-popup {
      right: -11px;
      &::before {
        right: 48px;
      }
    }
  }
}

.review-line {
  display: flex;
  align-items: center;
  &__rating {
    width: 85px;
    height: auto;
    margin-right: 15px;
  }
}

.section-404 {
  padding: 30px 0 0 0;
  &__container {
    max-width: 780px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    margin: 0 0 25px 0;
  }
  &__text {
    max-width: 380px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-right: 30px;
    flex: 1 1 100%;
  }
  &__icon {
    width: 180px;
    height: auto;
    flex: 0 0 auto;
  }
  &__button {
    margin: 35px 0 0 0;
    display: block;
    max-width: 120px;
  }
  @include media-breakpoint-down(lg) {
    padding: 42px 0 0 0;
    &__text {
      font-size: 1rem;
    }
    &__icon {
      width: 130px;
    }
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
    &__container {
      flex-direction: column;
      align-items: center;
    }
    &__icon {
      order: 1;
      margin: 0 0 38px 0;
    }
    &__title {
      font-size: 1.375rem;
    }
    &__text {
      order: 2;
      font-size: 0.75rem;
      margin: 0;
    }
    &__button {
      margin: 32px 0 0 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.tab-heading {
  display: flex;
  position: relative;
  z-index: 2;
  margin-bottom: -1px;
  &__link {
    text-decoration: none;
    color: var(--color-accent-darker, #1C52AC);
    border-color: transparent;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-radius: 2px 2px 0 0;
    display: block;
    padding: 14px 20px;
    transition: background-color $trs1, color $trs1, border-color $trs1;
    &:not(:first-child) {
      margin-left: -1px;
    }
    &[data-touch], &[data-hover] {
      color: var(--color-accent, #2363D1);
    }
    &.active {
      pointer-events: none;
      background-color: #fff;
      color: var(--color-text-muted, #767676);
      border-color: var(--color-table-border, #E5E5EA);
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 25px;
    &__link {
      border: 1px solid var(--color-button1-border, #B7CBE5);
      color: var(--color-button1, #1C52AC);
      background-color: var(--color-button1-bg, transparent);;
      border-radius: 4px;
      padding: 12px 20px;
      font-size: 1rem;
      &:not(:first-child) {
        margin-left: 16px;
      }
      &[data-touch], &[data-hover], &.active {
        color: var(--color-button1-hover, #fff);
        background-color: var(--color-button1-hover-bg, #E7EEF6);
        border-color: var(--color-button1-hover-border, #2363D1);
      }
    }
  }
}

.item-info {
  &__advertising {
    padding-top: 50px;
  }
  &__tab-head {
    display: flex;
    position: relative;
    z-index: 2;
  }
  &__tab-link {
    text-decoration: none;
    color: var(--color-accent-darker, #1C52AC);
    border-color: transparent;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-radius: 2px 2px 0 0;
    display: block;
    padding: 14px 20px;
    transition: background-color $trs1, color $trs1, border-color $trs1;
    &:not(:first-child) {
      margin-left: -1px;
    }
    &[data-touch], &[data-hover] {
      color: var(--color-accent, #2363D1);
    }
    &.active {
      pointer-events: none;
      background-color: #fff;
      color: var(--color-text-muted, #767676);
      border-color: var(--color-table-border, #E5E5EA);
    }
  }
  &__content {
    margin-top: -1px;
    background-color: #fff;
  }
  &__block {
    &-content {
      border-radius: 0;
    }
    &-head {
      display: none;
    }
  }
  &__container {
    &:not(:last-child) {
      margin-bottom: var(--small-content-margin);
    }
  }
  @include media-breakpoint-up(lg) {
    &__block {
      &-content {
        display: block !important;
        padding: 0 !important;
        border: 0 !important;
        border-radius: 0 !important;
        background-color: transparent !important;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__tab-head {
      display: none !important;
    }
    &__block {
      display: block !important;
      &:not(:last-child) {
        margin-bottom: 1px;
      }
      &.active {
        margin-bottom: 0 !important;
      }
      &-head {
        display: block;
        width: 100%;
        height: 50px;
        text-decoration: none;
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding: 13px 20px;
        background-color: var(--color-tabs-head, #E5E5EA);
        color: inherit;
        position: relative;
        transition: color $trs1;
        .icon {
          position: absolute;
          right: 20px;
          width: 12px;
          height: 12px;
          top: 18px;
          transition: transform $trs1, fill $trs1;
        }
        &.active {
          .icon {
            transform: rotate(180deg);
          }
        }
        &[data-touch], &[data-hover] {
          color: var(--color-accent, #2363D1);
          .icon {
            fill: var(--color-accent, #2363D1);
          }
        }
      }
    }
    &__content {
      padding: 0 !important;
      border: 0 !important;
      border-radius: 0 !important;
      background-color: transparent;
    }
  }
  @include media-breakpoint-down(sm) {
    &__specifications {
      font-size: 1.125rem;
      p {
        font-size: inherit;
        line-height: 1.5rem;
      }
    }
    &__block {
      &-head {
        margin: 0 -16px;
        padding-left: 16px;
        padding-right: 16px;
        width: calc(100% + 32px);
      }
      &-content {
        padding: 30px 0 40px 0;
      }
    }
  }
}

.project-calculator {
  &__total {
    border-top: 3px solid var(--color-accent, #2363D1);
    text-align: right;
    padding: 20px 20px 0 20px;
    margin-top: 30px;
    &-value {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.5rem;
      display: flex;
      justify-content: flex-end;
      span {
        color: var(--color-accent, #2363D1);
        margin-left: 1rem;
      }
    }
  }
  @include media-breakpoint-down(md) {
    &__total {
      margin-top: 24px;
      padding: 10px 16px 0 16px;
      &-value {
        justify-content: space-between;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__total {
      padding: 10px 16px 0 16px;
      margin: 20px -16px 0 -16px;
    }
  }
  &-block {
    &:not(:first-child) {
      margin-top: 1px;
    }
    &__content {
      margin-bottom: 20px;
    }
    &__head {
      position: relative;
      display: flex;
      justify-content: space-between;
      background-color: var(--color-sections-bg, #F5F5F5);
      padding: 12px 20px;
      cursor: pointer;
      user-select: none;
      &.active {
        .project-calculator-block__arrow {
          transform: rotate(180deg);
        }
      }
    }
    &__title {
      margin: 0;
      font-weight: 400;
    }
    &__head-price {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.5rem;
      padding-right: 40px;
    }
    &__arrow {
      position: absolute;
      right: 20px;
      top: 12px;
    }
    @include media-breakpoint-down(md) {
      &__arrow {
        right: 16px;
      }
      &__head {
        padding: 12px 16px;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-left: -16px;
      margin-right: -16px;
    }
  }
  &-table {
    font-size: 0.875rem;
    width: 100%;
    border-collapse: collapse;
    &__input {
      width: 100%;
      max-width: 130px;
      padding: 0 !important;
      input {
        text-align: center;
      }
    }
    &__item {
      &-price {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
      &-label {
        display: none;
      }
    }
    th {
      padding: 20px;
      text-align: left;
      font-weight: 700;
      border-bottom: 1px solid var(--color-table-border, #E5E5EA);
    }
    td {
      padding: 12px 20px;
      border-bottom: 1px solid var(--color-table-border, #E5E5EA);
    }
    @include media-breakpoint-down(md) {
      td {
        border: none;
        padding: 12px 16px;
        &:nth-child(odd) {
          padding-right: 8px;
          flex: 1 1 60%;
        }
        &:nth-child(2n) {
          padding-left: 8px;
          flex: 1 1 40%;
        }
      }
      &__head {
        display: none;
      }
      &__item {
        &-count {
          font-size: 1rem;
          line-height: 1.5rem;
        }
        &-price {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
          margin-top: 10px;
        }
        &-title {
          font-weight: 700;
          line-height: 1.5rem;
        }
        &-label {
          display: block;
          color: #000;
          font-size: 0.75rem;
          color: var(--color-text-light, #BDBDBD);
          margin-bottom: 4px;
        }
      }
      &__section {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--color-border-2, #BDBDBD);
      }
    }
    @include media-breakpoint-down(sm) {
    }
  }
}

.documents {
  &:not(:last-child) {
    margin-bottom: 28px;
  }
  &__title {
    margin-top: 0;
  }
  &__button {
    min-width: 0px;
  }
  &__table {
    width: 100%;
    tr {
      &:first-child {
        td {
          padding-top: 0;
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
      &:not(:last-child) {
        td {
          border-bottom: 1px solid var(--color-table-border, #E5E5EA);
        }
      }
    }
    td {
      padding: 12px 0;
      &:not(:last-child) {
        padding-right: 20px;
      }
    }
  }
  &__item {
    &-size {
      white-space: nowrap;
    }
    &-title {
      width: 100%;
    }
    &-link {
      color: inherit;
      margin-right: 30px;
      display: inline-block;
      max-width: 380px;
    }
    &-icon {
      &-item {
        margin-right: 20px;
        position: relative;
        .icon {
          width: 32px;
          height: 40px;
          fill: var(--color-accent, #2363D1);
          position: relative;
          z-index: 1;
          display: block;
        }
        span {
          display: block;
          width: 100%;
          text-align: center;
          position: absolute;
          white-space: nowrap;
          z-index: 2;
          bottom: 2px;
          color: #fff;
          font-size: 0.75rem;
          line-height: 1.125rem;
          text-transform: uppercase;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    &__item {
      &-link {
        margin: 0;
      }
      &-icon {
        &-item {
          margin: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    &__title {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    &__table {
      td {
        border: none !important;
        padding: 0;
        &:not(:last-child) {
          padding: 0;
        }
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding-left: 48px;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
    &__item {
      &-title {
        padding: 0;
        margin-bottom: 8px;
        display: block;
      }
      &-icon {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 0 !important;
      }
      &-button {
        display: none;
      }
    }
  }
}

.project-layouts {
  margin-bottom: -60px;
  &-block {
    margin-bottom: 60px;
    .image {
      width: 100%;
      margin-bottom: 20px;
      &::before {
        content: '';
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: block;
        object-fit: contain;
      }
    }
    &__title {
      margin: 0 0 20px 0;
    }
    &:nth-child(odd) {
      .project-layouts-block__container {
        padding-right: 24px;
      }
    }
    &:nth-child(2n) {
      .project-layouts-block__container {
        padding-left: 24px;
      }
    }
    @include media-breakpoint-down(xl) {
      &:nth-child(odd) {
        .project-layouts-block__container {
          padding-right: 12px;
        }
      }
      &:nth-child(2n) {
        .project-layouts-block__container {
          padding-left: 12px;
        }
      }
    }
    @include media-breakpoint-down(md) {
      &:nth-child(odd) {
        .project-layouts-block__container {
          padding-right: 0;
        }
      }
      &:nth-child(2n) {
        .project-layouts-block__container {
          padding-left: 0;
        }
      }
    }
  }
}

.order {
  .calc-count-block {
    margin: 0 auto;
  }
  @include media-breakpoint-down(sm) {
    &__container {
      padding-top: 24px;
    }
  }
  &-section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    &__title {
      margin: 0 0 20px 0;
      padding-left: 38px;
      position: relative;
      span {
        display: block;
      }
      .icon {
        stroke: var(--color-accent, #2363D1);
        fill: var(--color-accent, #2363D1);
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
    @include media-breakpoint-down(sm) {
      &__title {
        margin-bottom: 24px;
      }
    }
  }
  &-table {
    width: 100%;
    tr {
      &:first-child {
        td {
          padding-top: 0;
        }
      }
    }
    &__item {
      &-section {
        border-bottom: 1px solid var(--color-table-border, #E5E5EA);
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
    &__close {
      padding-left: 38px;
      padding-right: 38px;
    }
    &__close-button {
      display: block;
      width: 18px;
      height: 18px;
      .icon {
        width: 18px;
        height: 18px;
        fill: var(--color-text-light, #BDBDBD);
        transition: fill $trs1;
      }
      &[data-touch], &[data-hover] {
        .icon {
          fill: var(--color-accent, #2363D1);
        }
      }
    }
    &__text {
      width: 100%;
      padding-right: 100px;
    }
    &__index {
      display: block;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: var(--color-text-code, #7C8994);
      margin-bottom: 2px;
    }
    &__title {
      display: block;
      max-width: 380px;
    }
    &__price {
      white-space: nowrap;
      padding-right: 20px;
    }
    &__calc {
      padding-right: 40px;
    }
    &__total-price {
      padding-right: 80px;
      white-space: nowrap;
      font-weight: 700;
    }
    @include media-breakpoint-down(lg) {
      &__close {
        padding-left: 20px;
        padding-right: 20px;
      }
      &__text {
        padding-right: 20px;
      }
      &__calc {
        padding-right: 20px;
      }
      &__total-price {
        padding-right: 20px;
      }
    }
    @include media-breakpoint-down(sm) {
      margin: 0 -16px;
      width: calc(100% + 32px);
      &__item {
        padding: 24px 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-table-border, #E5E5EA);
        &:first-child {
          padding-top: 0;
        }
        &-section {
          padding: 0;
          border-bottom: none;
        }
      }
      &__close {
        padding-right: 40px;
        margin-bottom: 10px;
      }
      &__price {
        flex: 1 1 33.33%;
      }
      &__total-price {
        flex: 1 1 33.33%;
        text-align: right;
      }
      &__calc {
        flex: 1 1 33.33%;
      }
      &__text {
        width: auto;
        flex: 1 1 calc(100% - 60px);
        margin-bottom: 10px;
      }
    }
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    padding: 30px 80px 40px 0;
    border-bottom: 1px solid var(--color-table-border, #E5E5EA);
    @include media-breakpoint-down(xl) {
      padding: 32px 80px 32px 0;
    }
    @include media-breakpoint-down(lg) {
      padding: 32px 20px 32px 0;
    }
    @include media-breakpoint-down(sm) {
      margin: 0 -16px;
      padding: 32px 16px;
    }
  }
  &-total {
    tr {
      &:not(:last-child) {
        td {
          padding-bottom: 5px;
        }
      }
      &:last-child {
        td {
          font-weight: 700;
          color: #000;
          font-size: 1.125rem;
          line-height: 1.5rem;
          padding-top: 10px;
        }
      }
    }
    td {
      &:first-child {
        padding-right: 100px;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      td {
        &:first-child {
          padding-right: 20px;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  &-form-section {
    margin-top: 40px;
    &__head {
      margin-bottom: 24px;
    }
    &__head-item {
      margin-bottom: 16px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 54px;
      &__head {
        margin-bottom: 38px;
      }
    }

  }
  &-form {
    &__item {
        
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &__submit {
      padding: 0 35px;
    }
    @include media-breakpoint-down(sm) {
      &__submit {
        width: 100%;
      }
    }
  }
  &-final {
    &__table {
      width: 100%;
      td {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-table-border, #E5E5EA);
        vertical-align: top;
        &:not(:last-child) {
          padding-right: 30px;
        }
        &:not(:first-child) {
          white-space: nowrap;
        }
        &:last-child {
          font-weight: 700;
        }
        &:first-child {
          width: 100%;
          position: relative;
          span {
            position: absolute;
            right: 100%;
            top: 12px;
            transform: translateX(-20px);
          }
        }
        &:nth-child(2) {
          min-width: 120px;
          text-align: center;
        }
        &:nth-child(3) {
          min-width: 80px;
          text-align: center;
        }
        &:last-child {
          min-width: 80px;
          text-align: right;
        }
      }
    }
    &__button {
      margin-top: 30px;
      min-width: 200px;
    }
    &__total {
      margin-top: 20px;
      display: flex;
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: #000;
      span {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
    &__container {
      padding: 40px 100px;
      background-color: #fff;
    }
    p {
      max-width: 340px;
    }
    &__section {
      margin-bottom: 30px;
    }
    &__title {
      margin: 0 0 20px 0;
      position: relative;
      .icon {
        fill: var(--color-accent, #2363D1);
        width: 32px;
        height: 28px;
        position: absolute;
        display: block;
        left: -20px;
        transform: translateX(-100%);
        top: 0;
      }
    }
    @include media-breakpoint-down(xl) {
      &__container {
        padding: 40px;
      }
      &__section {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
      &__title {
        padding-left: 50px;
        .icon {
          transform: translate(0);
          left: 0;
        }
      }
      &__table {
        td:first-child {
          padding-left: 25px;
          span {
            left: 0;
            right: auto;
            transform: translate(0);
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      &__container {
        padding: 30px;
       }
      &__table {
        td {
          min-width: auto !important;
          &:not(:last-child) {
            padding-right: 20px;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      &__container {
        padding: 30px 16px;
      }
    }
    @include media-breakpoint-down(sm) {
      &__container {
        padding: 30px 8px;
        margin: 0 -8px;
      }
      &__button {
        width: 100%;
      }
      &__total {
        justify-content: space-between;
      }
      &__table {
        margin: 0 -8px;
        width: calc(100% + 16px);
        tr {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid var(--color-table-border, #E5E5EA);
          padding: 24px 8px;
          &:first-child {
            padding-top: 0;
          }
        }
        td {
          border-bottom: 0;
          padding: 0;
          &:first-child {
            font-size: 0.75rem;
            line-height: 1.125rem;
            flex: 1 1 100%;
            padding-right: 0;
            margin-bottom: 8px;
            span {
              font-size: 0.875rem;
              top: 0;
            }
          }
          &:not(:first-child) {
            flex: 1 1 33%;
          }
          &:nth-child(2) {
            text-align: left;
            padding-left: 25px;
          }
        }
      }
    }
  }
}

.portfolio-project {
  &__slider {
    margin-bottom: 70px;
    .slick-dots {
      bottom: -30px;
    }
  }
  &__info {
    &-container {
      display: flex;
      flex-wrap: wrap;
      padding: 30px 15px;
      border: 3px solid var(--color-accent, #2363D1);
    }
    &-item {
      flex: 1 1 60%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:nth-child(1), &:nth-child(2) {
        margin-bottom: 48px;
      }
      &:nth-child(2n) {
        flex: 1 1 40%;
      }
      strong {
        font-size: 1.375rem;
        white-space: nowrap;
        line-height: 36px;
      }
      .icon {
        fill: var(--color-accent, #2363D1);
        stroke: var(--color-accent, #2363D1);
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &__text {
      &:not(:first-child) {
        padding-left: 25px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__slider {
      .image {
        height: auto;
        &:before {
          content: '';
          display: block;
          padding-top: 63%;
        }
      }
    }
    &__info {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(md) {
    &__info {
      &-container {
        padding: 20px 15px;
      }
    }
  }
}

.project-progress-block {
  &__container {
    background-color: #fff;
    border-radius: 2px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__image {
    height: 220px;
    flex: 0 0 auto;
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity $trs1;
      background: linear-gradient(0deg, rgba(35, 99, 209, 0.5), rgba(35, 99, 209, 0.5))
    }
    &.image_contain {
      background-color: var(--color-border-1, #D9D9DE);
      img {
        padding: 45px;
      }
    }
    &[data-touch], &[data-hover] {
      &:after {
        opacity: 1;
      }
    }
  }
  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: var(--color-border-1, #D9D9DE);
    border-radius: 0 0 2px 2px;
  }
  &__title {
    margin: 0 0 10px 0;
  }
  &__adress {
    position: relative;
    padding-left: 20px;
    margin: 0 0 13px 0;
    .icon {
      width: 12px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 2px;
      fill: var(--color-accent, #2363D1);
    }
  }
  &__progress {
    width: 150px;
    &-text {
      display: flex;
      justify-content: space-between;
      strong {
        color: #000;
      }
    }
    &-line {
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 8px;
      background-color: var(--color-sections-bg, #F5F5F5);
      span {
        display: block;
        height: 100%;
        border-radius: inherit;
        background-color: var(--color-accent, #2363D1);
        margin-top: 5px;
      }
    }
  }
}

.title-index {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.125rem;
  transform: translateY(-20%);
  color: var(--color-text-code, #7C8994) !important;
  font-weight: normal;
}

.product-card {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-border-1, #D9D9DE);
    border-radius: var(--product-preview-radius, 2px);
    overflow: hidden;
    background-color: var(--color-product-card-bg, #fff);
    position: relative;
    transition: border-color $trs1;
    &[data-touch], &[data-hover] {
      border-color: var(--color-border-3, #A7A7AB);
      .product-card__image .image img {
        transform: scale(1.05);
        &:not(:last-child) {
          opacity: 0;
          visibility: hidden;
        }
        &:not(:first-child) {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .button, .input {
    position: relative;
    z-index: 2;
  }
  .card-price__current {
    line-height: 20px;
    height: 20px;
  }
  .card-price__label {
    font-size: 12px;
    line-height: 14px;
  }
  &__card-price {
    margin-bottom: 12px;
    pointer-events: none;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 -4px;
    .button {
      min-width: 0px;
      position: relative;
    }
    .button_icon {
      flex: 0 0 auto;
    }
  }
  &__get-price {
    flex: 1 1 100%;
  }
  &__image {
    flex: 0 0 170px;
    pointer-events: none;
    position: relative;
    .image {
      height: 100%;
      img {
        padding: 20px;
        transition: opacity $trs1, visibility $trs1, transform $trs1;
        will-change: transform;
        &:not(:first-child) {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  &__link-area {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &__status {
    position: absolute;
    z-index: 3;
    right: 10px;
    top: 0;
    color: #999;
    font-size: 0.75rem;
    line-height: 20px;
    font-weight: 700;
  }
  &__index {
    color: var(--color-text-light, #BDBDBD);
    font-size: 0.75rem;
    line-height: 1rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  &__rating {
    position: absolute;
    display: block;
    width: 85px;
    right: 0;
    top: 0;
  }
  &__title {
    margin-top: 23px;
    display: block;
    color: inherit;
    text-decoration: none;
    transition: color $trs1;
    &[data-touch], &[data-hover] {
      color: var(--color-accent, #2363D1);
    }
  }
  &__content {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 16px 16px 16px;
    &-top {
      margin-bottom: 8px;
      position: relative;
      pointer-events: none;
    }
  }
  &__bottom-item {
    margin: 0 4px;
  }
  &__smaller-price-unit {
    display: none;
  }
  .image_no-image {
    background-color: var(--color-sections-bg, #F5F5F5);
    img {
      padding: 25px;
    }
  }
  .card-price__content {
    margin-right: -16px;
  }
  .calc-count-block {
    max-width: 65px;
  }
  @include media-breakpoint-up(sm) {
    &__title {
      max-height: 60px;
      overflow: hidden;
    }
  }
  @include media-breakpoint-up(lg) {
    .slick-slide & {
      min-height: 375px;
    }
  }
  @include media-breakpoint-up(xl) {
    .slick-slide & {
      min-height: 380px;
    }
  }
  @include media-breakpoint-down(md) {
    &__buttons .button_icon {
      width: 66px;
    }
  }
  @include media-breakpoint-down(sm) {
    &_catalogue {
      .product-card {
        &__buttons {
          position: relative;
          .button_icon {
            display: none;
          }
        }
        &__add-to-cart {
          display: inline-flex !important;
          margin: 0;
          position: absolute;
          right: 0;
          bottom: 100%;
          width: 48px !important;
          padding: 0;
        }
        &__container {
          flex-direction: row;
          padding: 28px 16px 16px 16px;
        }
        &__content {
          padding: 0 0 0 16px;
          &-top {
            margin-bottom: 4px;
          }
        }
        &__status {
          top: 100%;
          right: auto;
          left: 0;
          margin-top: 2px;
        }
        &__index {
          bottom: 100%;
          top: auto;
          font-size: 10px;
          line-height: 13px;
          margin-bottom: 5px;
        }
        &__rating {
          bottom: 100%;
          top: auto;
          margin-bottom: 5px;
        }
        &__title {
          margin: 0;
        }
        &__image {
          flex: 0 0 70px;
          height: 70px;
          margin-top: 4px;
          img {
            padding: 5px;
          }
        }
      }
      .image_no-image img {
        padding: 10px;
      }
      .calc-count-block {
        position: absolute;
        right: 100%;
        width: 700px;
        margin-right: 13px;
        display: none;
      }
      .card-label {
        font-size: 10px;
        min-width: 36px;
        top: -32px;
        left: -16px;
      }
      .card-price {
        margin: 0;
       }
    }
    .card-price {
      position: relative;
      &__smaller-price-unit {
        display: inline-block !important;
        font-size: .8em;
      }
      &__current {
        span {
          display: none;
        }
      }
      &__no-price {
        display: none;
      }
      &__label {
        margin-bottom: 8px;
      }
      &__title {
        display: block;
        flex: 1 1 100%;
      }
      &__old {
        margin: 0;
        position: absolute;
        bottom: 20px;
        left: 62px;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .catalogue-blocks_row {
    .product-card {
      flex: 1 1 100%;
      max-width: 100%;
      &__status {
        top: auto;
        right: auto;
        left: 16px;
        bottom: 18px;
      }
      &__container {
        flex-direction: row;
        min-height: 150px;
      }
      &__image {
        flex: 0 0 190px;
      }
      &__rating {
        left: 70px;
        right: auto;
      }
      .card-price__label {
        position: absolute;
        left: 30px;
        bottom: 20px;
        margin: 0;
      }
      .card-price__content {
        flex-wrap: nowrap;
        margin: 0;
        justify-content: flex-end;
        padding-top: 24px;
        position: relative;
      }
      .card-price__title {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        color: var(--color-text-muted, #767676);
      }
      .card-price__current {
        margin: 0 0 0 8px;
        order: 2;
        span {
          display: none;
        }
      }
      .card-price__smaller-price-unit {
        display: inline-block!important;
        font-size: .8em;
      }
      .card-price__old {
        transform: translateY(2px);
      }
      &__buttons {
        justify-content: flex-end;
        margin: 0 -5px;
      }
      &__bottom-item {
        margin: 0 5px;
      }
      &__get-price {
        min-width: 130px;
      }
      &__content {
        flex-direction: row;
        padding: 20px 30px 20px 30px;
        position: relative;
        &-top {
          flex: 1 1 100%;
          padding-right: 40px;
          margin-bottom: 0;
          padding-bottom: 24px;
        }
        &-bottom {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .catalogue-blocks_row {
    .product-card {
      &__content {
        padding: 20px 40px;
      }
      .card-price__label {
        left: 40px;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .catalogue-blocks_row {
    .product-card {
      &__content {
        padding: 20px 40px 20px 48px;
      }
      .card-price__label {
        left: 48px;
      }
    }
  }
}




.small-wiki-card {
  &__container {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 100%;
    color: inherit;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      background-color: var(--color-accent-darker, #1C52AC);
      transition: opacity $trs1;
      border-radius: 2px;
    }
    &[data-touch], &[data-hover] {
      &:after {
        opacity: 0.5;
      }
    }
  }
  &__image {
    position: relative;
    z-index: 1;
    flex: 0 0 120px;
    border-radius: 2px 2px 0 0;
    overflow: hidden;
    img {
      filter: grayscale(100%);
    }
  }
  &__date {
    position: absolute;
    right: 16px;
    top: 12px;
    z-index: 2;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  &__content {
    position: relative;
    z-index: 1;
    border-radius: 0 0 2px 2px;
    border-color: var(--color-border-1, #D9D9DE);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    padding: 20px 16px 16px 16px;
    flex: 1 1 100%;
    background-color: var(--color-sections-bg, #F5F5F5);
  }
  &__label {
    background-color: var(--color-label-article, #D9D9DE);
    color: var(--color-accent, #2363D1);
    line-height: 1.5rem;
    font-size: 0.75rem;
    padding: 0 15px;
    position: absolute;
    left: 16px;
    top: 120px;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.large-wiki-card {
  &__container {
    display: flex;
    min-height: 220px;
    position: relative;
  }
  &__link {
    content: '';
    display: block;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: var(--color-accent-darker, #1C52AC);
    transition: opacity $trs1;
    border-radius: 2px;
    &[data-touch], &[data-hover] {
      opacity: 0.5;
    }
  }
  &__image {
    flex: 0 0 calc(50% - 10px);
    border-radius: 2px 0 0 2px;
    overflow: hidden;
  }
  &__content {
    flex: 1 1 50%;
    background-color: var(--color-sections-bg, #F5F5F5);
    border-color: var(--color-border-1, #D9D9DE);
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-radius: 0 2px 2px 0;
    padding: 20px;
  }
  &__label {
    background-color: var(--color-label-article, #D9D9DE);
    color: var(--color-accent, #2363D1);
    line-height: 1.5rem;
    font-size: 0.75rem;
    padding: 0 15px;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 3;
  }
  &__date {
    color: var(--color-text-muted, #767676);
    font-size: 0.75rem;
    text-transform: uppercase;
    line-height: 1rem;
    display: block;
    margin-bottom: 25px;
  }
  &__title {
    margin: 0;
  }
  &__button {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    &.grid__block {
      margin-bottom: 35px;
    }
    &__container {
      flex-direction: column;
      margin-left: -16px;
      margin-right: -16px;
    }
    &__link {
      display: none;
    }
    &__date {
      font-size: 0.875rem;
      line-height: 1rem;
      margin: 0;
      position: absolute;
      right: 16px;
      color: #fff;
      z-index: 3;
      top: 16px;
    }
    &__content {
      flex: 0 0 auto;
      padding: 45px 16px 30px 16px;
      border: none;
    }
    &__title {
      margin: 0 0 25px 0;
    }
    &__button {
      display: inline-flex;
      width: 100%;
    }
    &__image {
      height: 180px;
      flex: 0 0 auto;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transition: opacity $trs1;
        opacity: 0.3;
        z-index: 2;
      }
      &[data-touch], &[data-hover] {
        &:before {
          opacity: 0.5;
        }
      }
    }
    &__label {
      top: 180px;
      line-height: 40px;
      width: 120px;
      transform: translateY(-50%);
      font-size: 1.125rem;
      text-align: center;
    }
  }
}

.contact-section {
  &:not(:last-child) {
    margin-bottom: 35px;
  }
  &__title {
    margin: 0 0 15px 0;
  }
}

.contact-block {
  border-radius: 2px;
  border: 1px solid var(--color-border-1, #D9D9DE);
  display: flex;
  min-height: 250px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &__content {
    padding: 40px 100px;
    flex: 1 1 100%;
  }
  &__map {
    flex: 0 0 480px;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    margin: 0 0 20px 0;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    padding-left: 40px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--color-border-1, #D9D9DE);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  @include media-breakpoint-down(xl) {
    &__content {
      padding: 40px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__content {
      flex: 1 1 50%;
      padding: 40px 30px;
    }
    &__map {
      flex: 1 1 50%;
    }
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    &__content {
      order: 2;
    }
    &__map {
      height: 200px;
      flex: 0 0 auto;
      order: 1;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__content {
      padding: 20px 16px;
    }
  }
}

.brigade {
  min-height: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}
.brigade-diagram {
  height: 200px;
  margin-top: 130px;
  position: relative;
  &__info {
    position: absolute;
    z-index: 3;
    bottom: 50%;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #fff;
      bottom: -4px;
      z-index: 2;
    }
    &-content {
      position: relative;
      padding-bottom: 75px;
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    span {
      color: var(--color-accent, #2363D1);
      font-size: 70px;
      line-height: 0.9;
      font-weight: 700;
      margin-right: 16px;
    }
    &-line {
      display: block;
      width: auto;
      height: 60px;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
    &_style-1 {
      right: 50%;
      .brigade-diagram__info-content {
        padding-right: 40px;
      }
      &:before {
        right: -4px;
      }
      .brigade-diagram__info-line {
        right: 0;
      }
    }
    &_style-2 {
      left: 50%;
      .brigade-diagram__info-content {
        padding-left: 40px;
      }
      &:before {
        left: -4px;
      }
      .brigade-diagram__info-line {
        left: 0;
      }
    }
  }
  &__circle {
    width: 200px;
    height: 200px;
    fill: transparent;
    position: relative;
    margin: 0 auto;
    &-item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      &_style-1 {
        stroke: var(--color-diagram-1, #FF9500);
      }
      &_style-2 {
        stroke: var(--color-diagram-2, #2363D1);
        transform: rotate(-90deg);
      }
    }
  }
  @include media-breakpoint-down(xl) {
    margin-top: 110px;
    &__info_style-2 .brigade-diagram__info-content {
      transform: translate(-15%, 15%) scale(0.7);
    }
    &__info_style-1 .brigade-diagram__info-content {
      transform: translate(15%, 15%) scale(0.7);
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 30px;
    margin-bottom: 65px;
    &__circle {
      width: 100%;
    }
    &__info {
      bottom: 0!important;
      position: absolute;
      top: calc(100% + 20px);
      transform: translate(0) !important;
      &::before {
        display: none;
      }
      &-line {
        display: none;
      }
      &-content {
        transform: translate(0) !important;
        padding: 0 !important;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
      &_style-1 {
        left: 0;
        span {
          color: var(--color-diagram-1, #FF9500);
        }
      }
      &_style-2 {
        right: 0;
        span {
          color: var(--color-diagram-2, #2363D1);
        }
      }
      span {
        font-size: 48px;
        margin-right: 10px;
      }
    }
  }
}


.product-section {
  &__product-links {
    margin-bottom: 15px;
  }
  &__head {
    position: relative;
  }
  &__chart {
    background-color: var(--color-sections-bg, #F5F5F5);
    padding: 20px 30px;
    margin-bottom: 20px;
    .button {
      min-width: 0px;
      width: 100%;
    }
    &-content {
      margin-bottom: 10px;
    }
    &-dates {
      display: flex;
      text-transform: uppercase;
      color: var(--color-text-light, #BDBDBD);
      font-size: 0.625rem;
      line-height: 0.875rem;
      height: 0.875rem;
      margin-bottom: 5px;
      position: relative;
      span {
        position: absolute;
        &:nth-child(2) {
          left: 50%;
          transform: translateX(-50%);
        }
        &:last-child {
          left: 0;
          transform: translateX(-50%);
        }
        &:first-child {
          right: 0;
          transform: translateX(50%);
        }
      }
    }
    &-icon {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }
  &__title {
    padding-right: 80px;
  }
  &__index {
    position: absolute;
    right: 30px;
    top: 28px;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: var(--color-text-light, #BDBDBD);
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    &-link {
      text-decoration: none;
      color: var( --color-text-light, #BDBDBD);
      padding: 0 50px;
      flex: 1 1 50%;
      line-height: 38px;
      position: relative;
      transition: color $trs1, background-color $trs1;
      .icon {
        fill: var( --color-text-light, #BDBDBD);
        position: absolute;
        top: 50%;
        width: 14px;
        height: 14px;
        top: 12px;
        transition: fill $trs1;
      }
      span {
        &:last-child {
          display: none;
        }
      }
    }
    &-prev {
      .icon {
        left: 30px;
      }
    }
    &-next {
      text-align: right;
      .icon {
        right: 30px;
      }
    }
  }
  &__content {
    border-radius: 0 0 2px 2px;
    display: flex;
    @include media-breakpoint-up(xl) {
      padding: 30px 80px;
    }
  }
  &__images {
    flex: 0 0 320px;
  }
  &__slider {
    position: relative;
    margin-bottom: 16px;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
      pointer-events: none;
    }
  }
  &__product-category {
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 18px;
    z-index: 2;
    font-weight: 700;
    opacity: 0.5;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  &__center {
    flex: 1 1 100%;
    padding: 0 70px;
    max-width: 400px;
  }
  &__personal-pricing-text {
    margin-bottom: 2px;
  }
  &__price {
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.25rem;
    margin-bottom: 10px;
  }
  &__sub-price {
    color: var(--color-text-muted, #767676);
  }
  &__old-price {
    color: var(--color-old-price, #C43228);
    text-decoration: line-through;
  }
  &__order-block {
    display: flex;
    align-items: center;
    margin: 15px 0 20px 0;
    .calc-count-block {
      flex: 0 0 64px;
      margin-right: 16px;
    }
  }
  &__button {
    flex: 1 1 100%;
  }
  @include media-breakpoint-up(sm) {
    &__title {
      margin: 0 !important;
    }
    &__nav {
      border-radius: 2px 2px 0 0;
      border: 1px solid var(--color-table-border, #E5E5EA);
      background-color: #fff;
      &-link {
        &[data-touch], &[data-hover] {
          color: var(--color-accent, #2363D1);
          background-color: var(--color-normal-light-bg-button, #F3F6FB);
          .icon {
            fill: var(--color-accent, #2363D1);
          }
        }
      }
    }
    &__head {
      padding: 15px 20px;
      position: relative;
      border-width: 0 1px;
    }
    &__head,
    &__nav {
      background-color: #fff;
      border-style: solid;
      border-color: var(--color-table-border, #E5E5EA);
    }
    &__nav {
      border-width: 1px;
      border-radius: 2px 2px 0 0;
    }
  }
  @include media-breakpoint-up(md) {
    &__head {
      padding: 20px 30px;
    }
  }
  @include media-breakpoint-down(xl) {
    &__images {
      flex: 1 1 35%;
      max-width: 35%;
    }
    &__center {
      max-width: 340px;
      padding: 0 40px;
      flex: 1 1 40%;
    }
  }
  @include media-breakpoint-down(lg) {
    &__index {
      left: 50%;
      right: auto;
      top: auto;
      bottom: 100%;
      transform: translate(-50%, -10px);
    }
    &__title {
      padding: 0;
    }
    &__images {
      flex: 1 1 50%;
      max-width: 50%;
    }
    &__center {
      padding: 0 0 0 30px;
      flex: 1 1 50%;
    }
    &__content {
      flex-wrap: wrap;
    }
    &__nav-link {
      flex: 1 1 40%;
      max-width: 40%;
      span {
        display: none;
        &:last-child {
          display: block;
        }
      }
    }

  }
  @include media-breakpoint-down(md) {
    &__nav-next {
      padding: 0 40px 0 0;
      .icon {
        right: 20px;
      }
    }
    &__nav-prev {
      padding: 0 0 0 40px;
      .icon {
        left: 20px;
      }
    }
    .calc-count-block {
      flex: 0 0 115px;
      margin-right: 20px;
      &__button {
        width: 40px;
        height: 40px;
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
    .nav-slider {
      display: none;
    }
    &__chart {
      margin-bottom: 24px;
    }
    &__slider {
      margin: 0;
      padding-bottom: 35px;
      .slick-dots {
        bottom: 0;
      }
    }
    &__images {
      flex: 1 1 100%;
      max-width: 100%;
      margin-bottom: 30px;
    }
    &__center {
      flex: 1 1 100%;
      max-width: 100%;
      padding: 0;
    }
    &__price {
      margin-bottom: 2px;
    }
    &__order-block {
      margin: 20px 0 24px 0;
    }
    &__sub-price {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    &__comparison {
      flex: 0 0 auto;
      min-width: 40px;
      padding: 0 !important;
      .icon {
        margin: 0 !important;
      }
      span {
        display: none;
      }
    }
    &__product-links {
      margin: 0 0 24px 0;
      .product-links__share {
        flex: 1 1 100%;
        .button_style-3 {
          width: 100%;
        }
      }
      .button_style-3 {
        height: 40px;
        line-height: 38px;
        padding: 0 15px;
        border-radius: 2px;
        border: 1px solid var(--color-button1-border, #B7CBE5);
        color: var(--color-button1, #1C52AC);
        background-color: var(--color-button1-bg, transparent);;
        .icon {
          height: 18px;
          width: 18px;
          &:not(:last-child) {
            margin-right: 12px;
          }
          fill: var(--color-button1-icon, #2363D1);
          stroke: var(--color-button1-icon, #2363D1);
        }
        &[data-touch], &[data-hover] {
          color: var(--color-button1-hover, #fff);
          background-color: var(--color-button1-hover-bg, #E7EEF6);
          border-color: var(--color-button1-hover-border, #2363D1);
          .icon {
            fill: var(--color-button1-hover-icon, #1C52AC);
            stroke: var(--color-button1-hover-icon, #1C52AC);
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__review-line {
      justify-content: space-between;
    }
    &__nav {
      margin-bottom: 20px;
    }
    &__index {
      transform: translate(-50%,-22px);
    }
    &__nav-link {
      max-width: calc(50% - 40px);
      line-height: 24px;
      .icon {
        top: 6px;
      }
      &[data-touch], &[data-hover] {
        color: var(--color-accent, #2363D1);
        .icon {
          fill: var(--color-accent, #2363D1);
        }
      }
    }
    &__nav-next {
      padding: 0 20px 0 0;
      .icon {
        right: 0;
      }
    }
    &__nav-prev {
      padding: 0 0 0 20px;
      .icon {
        left: 0;
      }
    }
  }
}

.product-info-block {
  flex: 0 0 300px;
  color: var(--color-text-muted, #767676);
  &__section {
    padding: 20px 40px;
    border-radius: 2px;
    background-color: var(--color-sections-bg, #F5F5F5);
    &:not(:last-child) {
      margin-bottom: 1px;
    }
  }
  &__button {
    margin-top: 14px;
    width: 100%;
    max-width: 180px;
  }
  &__title {
    margin-bottom: 2px;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding-left: 18px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      &:before {
        content: '—';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    flex: 1 1 25%;
    max-width: 25%;
    &__section {
      padding: 15px 20px;
    }
  }
  @include media-breakpoint-down(lg) {
    flex: 1 1 100%;
    max-width: 100%;
    margin-top: 40px;
  }
  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }
  @include media-breakpoint-down(sm) {
    &__section {
      padding: 15px;
    }
    &__button {
      width: 100%;
      max-width: 100%;
    }
  }
}

.item-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &__slide {
    display: block;
    width: 100%;
    position: relative;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.nav-slider {
  padding: 0 42px;
  &__arrow {
    width: 40px;
    height: 40px;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .icon {
      margin: auto;
      width: 16px;
      height: 16px;
      fill: var(--color-accent, #2363D1);
      transition: fill $trs1;
    }
    &-next {
      right: 0;
    }
    &-prev {
      left: 0;
    }
    &[data-touch], &[data-hover] {
      .icon {
        fill: var(--color-accent-darker, #1C52AC);
      }
    }
  }
  &__slide {
    padding: 0 8px;
    .image {
      cursor: pointer;
      border-radius: 2px;
      border: 1px solid var(--color-table-border, #E5E5EA);
      height: 48px;
      transition: border-color $trs1;
    }
    &.slick-current {
      .image {
        border-color: var(--color-accent, #2363D1);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .project-preview-card {
    &__image {
      flex: 0 0 200px;
      max-height: 200px;
    }
  }
} 
@include media-breakpoint-down(md) {
  .logo {
    &__link {
      display: block;
      width: 160px;
    }
  }
  .header {
    &__nav-group {
      flex: 0 0 auto;
    }
  }
  .category-block {
    &__title {
      font-size: 1.125rem;
    }
  }
  .section-partners {
    &__slider {
      padding: 0 80px;
      .slick-list {
        margin: 0 -10px;
      }
    }
    &__slide {
      padding: 0 10px;
    }
  }
} 



@include media-breakpoint-down(sm) {
  .logo {
    &__link {
      width: 117px;
    }
  }
  .section-partners {
    &__title {
      margin-bottom: 15px;
    }
    &__text {
      font-size: 0.75rem;
      margin-bottom: 30px;
    }
    &__slider {
      margin-top: 30px;
    }
  }
} 

.fancybox-button {
  border-radius: 0;
  padding: 0;
  width: 40px;
  .icon {
    width: 16px;
    height: 16px;
  }
  &--arrow_left {
    transform: rotate(180deg);
  }
}

.bid-section {
  .form {
    margin-top: 25px;
  }
  &__slider {
    .image {
      height: auto;
      &:before {
        content: '';
        display: block;
        padding-top: 63%;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__form {
      margin-bottom: 30px;
    }
  }
}

.prp-section {
  &__content {
    background-color: #fff;
    height: 100%;
    padding: 40px;
  }
  @include media-breakpoint-down(lg) {
    &__slider {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    &__content {
      padding: 0;
      background-color: transparent;
    }
  }
}

.preview-block {
  &__container {
    display: block;
    color: #fff;
    text-decoration: none;
    transition: opacity $trs1;
    &[data-touch], &[data-hover] {
      .preview-block__image:after {
        opacity: 0.2;
      }
    }
  }
  &__image {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      display: block;
      padding-top: 100%;
      position: relative;
      z-index: 2;
      background-color: #000;
      opacity: 0;
      transition: opacity $trs1;
    }
  }
  &__content {
    padding: 24px 16px;
    position: relative;
    z-index: 2;
  }
  &__label {
    display: block;
    position: absolute;
    top: 0;
    left: 16px;
    transform: translateY(-50%);
    padding: 0 10px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10px;
    color: #000;
  }
  &__title {
    color: inherit;
    font-weight: normal;
    margin: 0;
  }
}

.personal-form {
  &__title {
    margin-top: 0;
  }
  &__text {
    font-size: 1rem;
    line-height: inherit;
  }
  @include media-breakpoint-up(lg) {
    &__text {
      font-size: 1.125rem;
    }
  }
  @include media-breakpoint-down(xl) {
    &__text {
      margin-bottom: 28px;
    }
  }
}

.section-text-image {
  &__image {
    .image {
      min-height: 260px;
      height: 100%;
    }
  }
  @include media-breakpoint-down(xl) {
    &__content {
      margin-bottom: 30px;
    }
  }
  @include media-breakpoint-down(sm) {
    .image {
      margin: 0 -16px;
    }
  }
}

.landing-banner {
  min-height: 480px;
  height: auto !important;
  .slick-dots {
    bottom: 34px !important;
  }
  &__slide {
    position: relative;
    .container {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &__form {
    color: #fff;
    position: relative;
    z-index: 2;
    max-width: 420px;
    margin: 0 0 0 auto;
    &-title {
      color: inherit;
      margin: 0 0 20px 0;
      font-size: 42px;
      line-height: 1;
    }
    &-subtitle {
      color: inherit;
      margin: 0;
      font-size: 24px;
      line-height: 1.25;
    }
    &-subscription {
      margin: 20px 0 0 0;
    }
  }
  @include media-breakpoint-down(sm) {
    min-height: 400px;
    &__form {
      text-align: center;
      padding: 0 16px;
      &-title {
        font-size: 1.375rem;
        line-height: 1.75rem;
        padding: 0 16px;
      }
      &-subtitle {
        padding: 0 16px;
        font-size: 18px;
      }
    }
  }
}

.wide-project-card {
  &__container {
    height: 100%;
    border: 1px solid var(--color-border-1, #D9D9DE);
    border-radius: 2px;
    background-color: var(--color-service-card-bg, #fff);
    min-height: 180px;
    display: flex;
  }
  &__image {
    flex: 0 0 320px;
    position: relative;
    z-index: 1;
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity $trs1;
      background: linear-gradient(0deg, rgba(35, 99, 209, 0.5), rgba(35, 99, 209, 0.5))
    }
    &[data-touch], &[data-hover] {
      &:after {
        opacity: 1;
      }
    }
  }
  &__content {
    padding: 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }
  &__index {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--color-text-light, #BDBDBD);
    font-size: 0.75rem;
  }
  &__title {
    margin-top: 0;
  }
  &__text {
    margin-bottom: 15px;
  }
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -15px;
  }
  &__calc {
    max-width: 65px;
    .input__element {
      padding: 0;
      line-height: 20px;
    }
  }
  &__button, &__calc, &__price {
    margin-bottom: 15px;
  }
  @include media-breakpoint-down(lg) {
    &__image {
      flex: 0 0 332px;
    }
  }
  @include media-breakpoint-down(md) {
    &__container {
      flex-direction: column;
    }
    &__image {
      flex: 0 0 200px;
    }
    &__content {
      padding: 12px 12px 15px 12px;
    }
    &__index {
      padding: 13px;
      left: 0;
      top: 0;
      right: initial;
      transform: translateY(-100%);
      color: #fff;
    }
    &__title {
      margin-bottom: 10px;
    }
    &__text {
      margin-bottom: 12px;
    }
    &__price {
      flex: 1 1 100%;
    }
    &__button {
      flex: 1 1 auto;
    }
    &__calc {
      flex: 0 0 auto;
      margin-right: 12px;
    }
  }
}

.faq-element {
  position: relative;
  background-color: var(--color-faq-element-bg, #f5f5f5);
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &__head {
    padding: 15px 70px 15px 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    cursor: pointer;
    &[data-touch], &[data-hover] {
      .faq-element__trigger {
        color: var(--color-button1-hover, #fff);
        background-color: var(--color-button1-hover-bg, #E7EEF6);
        border-color: var(--color-button1-hover-border, #2363D1);
        .icon {
          fill: var(--color-button1-hover-icon, #1C52AC);
          stroke: var(--color-button1-hover-icon, #1C52AC);
        }
      }
    }
    &.active {
      .faq-element__trigger {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__head-title {
    margin: 0;
  }
  &__trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 1px solid var(--color-button1-border, #B7CBE5);
    background-color: var(--color-button1-bg, transparent);
    transition: border-color $trs1, background-color $trs1;
    .icon {
      fill: var(--color-button1-icon, #2363D1);
      width: 14px;
      height: 14px;
      transition: transform $trs1;
    }
  }
  &__content {
    padding: 5px 20px 20px 20px;
  }
  @include media-breakpoint-down(sm) {
    &__content {
      padding: 5px 15px 15px 15px;
    }
    &__head {
      padding: 15px 70px 15px 15px;
    }
    &__head-title {
      font-size: 1rem;
      line-height: 1.3;
    }
  }
}

.product-slider {
  &_rows-2, &_rows-3 {
    .slick-slide {
      display: grid;
      grid-auto-rows: 1fr;
    }
  }
}

.affiliate-network {
  &__map {
    margin-bottom: 40px;
  }
  &__map-head {
    margin-bottom: 25px;
  }
  &__map-element {
    height: 500px;
    width: 100%;
    display: block;
    background-color: #D9D9DE;
  }
  &__text-element {

  }
  &__title-element {
    margin-top: 0;
  }
  @include media-breakpoint-down(xl) {
    &__map-element {
      height: 400px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__map {
      margin-bottom: 30px;
    }
    &__map-element {
      height: 300px;
    }
  }
}

.contact-box {
  &__container {
    border-radius: 2px;
    border: 1px solid var(--color-border-1, #D9D9DE);
    padding: 30px 20px;
    height: 100%;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__title {
    margin: 0 0 20px 0;
  }
  &__item {
    padding-left: 40px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--color-border-1, #D9D9DE);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.image-card-item {
  .image {
    height: 200px;
  }
  &__title {
    margin: 10px 0 0 0;
    font-size: 0.875rem;
  }
}

.banner-item-element {
  display: block;
  position: relative;
  height: 100%;
  min-height: 160px;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    transition: transform $trs1;
    will-change: transform;
  }
  &[data-touch], &[data-hover] {
    .banner-item-element__image {
      transform: scale(1.05);
    }
  }
  &__text-content {
    position: relative;
    z-index: 2;
    padding: 25px 20px;
  }
}